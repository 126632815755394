export const PageName = Object.freeze({
  home: "home",
  about: "about",
  login: "login",
  resetPassword: "resetPassword",
  customerList: "customerList",
  customerListDetail: "customerListDetail",
  customerAccountBalance: "customerAccountBalance",
  customerAccountBalanceDetail: "customerAccountBalanceDetail",
  customerRatingList: "customerRatingList",
  customerRatingListDetail: "customerRatingListDetail",
  customerDeleteAccount: "customerDeleteAccount",
  companyCustomerList: "companyCustomerList",
  companyCustomerListDetail: "companyCustomerListDetail",
  companyCustomerAccountBalance: "companyCustomerAccountBalance",
  companyCustomerAccountBalanceDetail: "companyCustomerAccountBalanceDetail",
  companyCustomerPaymentNotification: "companyCustomerPaymentNotification",
  companyCustomerChequeList: "companyCustomerChequeList",
  companyCustomerChequeListDetail: "companyCustomerChequeListDetail",
  companyCustomerCreateInvoice: "companyCustomerCreateInvoice",
  TMUserList: "TMUserList",
  TMUserListDetail: "TMUserListDetail",
  TMList: "TMList",
  TMReport: "TMReport",
  TMRating: "TMRating",
  TMApplication: "TMApplication",
  TMApplicationDetail: "TMApplicationDetail",
  TMMiscCharge: "TMMiscCharge",
  TMSalary: "TMSalary",
  TMUserType: "TMUserType",
  TM418: "TM418",
  TMpayroll: "TMPayroll",
  TMDeleteAccount: "TMDeleteAccount",
  eCommerceServices: "eCommerceServices",
  eCommerceServiceProfile: "eCommerceServiceProfile",
  eCommerceServiceDetails: "eCommerceServiceDetails",
  eCommerceDiscount: "eCommerceDiscount",
  eCommerceBanner: "eCommerceBanner",
  eCommerceBannerDetail: "eCommerceBannerDetail",
  eCommerceIntroduction: "eCommerceIntroduction",
  adminList: "adminList",
  adminTypes: "adminTypes",
  adminTypeDetail: "adminTypeDetail",
  adminResetPassword: "adminResetPassword",
  orderList: "orderList",
  orderRemark: "orderRemark",
  orderCreate: "orderCreate",
  orderDetail: "orderDetail",
  orderPaymentList: "orderPaymentList",
  messageSMS: "messageSMS",
  messageSMSDetail: "messageSMSDetail",
  messagePush: "messagePush",
  messagePushDetail: "messagePushDetail",
  messageNotice: "messageNotice",
  messageNoticeDetail: "messageNoticeDetail",
  systemAppVersion: "systemAppVersion",
  systemContactUs: "systemContactUs",
  systemAboutUs: "systemAboutUs",
  systemFAQ: "systemFAQ",
  systemFAQDetail: "systemFAQDetail",
  systemTNC: "systemTNC",
  noAccessRight: "noAccessRight"
})
