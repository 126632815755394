import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getReviews = (payload = {}) => {
  let params = formatPayloadToParams(payload, [
    "orderNo",
    "customer.customerId",
    "customer.customerNo",
    "customer.name",
    "customer.phone",
    "customer.type",
    "tmUser.id",
    "tmUser.nameZh",
    "tmUser.phone",
    "tmUser.staffNo",
    "unresolvedBadComment",
    "recentLowRating",
    "sort",
    "order"
  ])
  return http.get("reviews", { params })
}
