const orderItemList = [
  { id: "ORDER", name: "SIDE_BAR_order_list", link: "/order/list", matchArray: ["/order/create"] },
  { id: "TM_REMARK", name: "SIDE_BAR_order_remark", link: "/order/tm-remark" }
  // { id: "PAYMENT", name: "SIDE_BAR_order_pay", link: "/order/payment-list" }
]
const clientItemList = [
  { id: "CUSTOMER", name: "SIDE_BAR_client_list", link: "/customer/list" },
  { id: "CUSTOMER_BALANCE", name: "SIDE_BAR_client_account", link: "/customer/account-balance" },
  { id: "CUSTOMER_REVIEW", name: "SIDE_BAR_client_score", link: "/customer/rating-list" },
  { id: "DELETE_CUSTOMER_AC", name: "SIDE_BAR_client_delete_account", link: "/customer/delete-account" }
]
const companyClientItemList = [
  {
    id: "ORG_CUSTOMER",
    name: "SIDE_BAR_companyClient_list",
    link: "/company-customer/list",
    matchArray: ["/company-customer/invoice/[customerId]"]
  },
  {
    id: "ORG_CUSTOMER_BALANCE",
    name: "SIDE_BAR_companyClient_account",
    link: "/company-customer/account-balance"
  },
  {
    id: "ORG_CUSTOMER_INVOICE",
    name: "SIDE_BAR_companyClient_pay",
    link: "/company-customer/payment-notification"
  },
  { id: "ORG_CUSTOMER_CHEQUE", name: "SIDE_BAR_companyClient_cheque", link: "/company-customer/cheque-list" }
]
const TMUserItemList = [
  { id: "TM", name: "SIDE_BAR_TM_user_list", link: "/TM-user/list" },
  { id: "TM_CALENDER", name: "SIDE_BAR_TM_user_report", link: "/TM-user/report" },
  { id: "TM_SCORE", name: "SIDE_BAR_TM_user_score", link: "/TM-user/rating" },
  { id: "TM_APPLICATION", name: "SIDE_BAR_TM_user_application", link: "/TM-user/application" },
  { id: "TM_MISC_CHARGE", name: "SIDE_BAR_TM_user_TM_charge", link: "/TM-user/misc-charge" },
  { id: "TM_SALARY", name: "SIDE_BAR_TM_user_salary", link: "/TM-user/salary" },
  { id: "TM_TYPE", name: "SIDE_BAR_TM_user_TM_type", link: "/TM-user/type" },
  { id: "TM_418", name: "SIDE_BAR_TM_user_418", link: "/TM-user/418" },
  { id: "TM_PAYROLL", name: "SIDE_BAR_TM_user_TM_payroll", link: "/TM-user/payroll" },
  { id: "DELETE_TM_USER_AC", name: "SIDE_BAR_TM_user_delete_account", link: "/TM-user/delete-account" }
]
const eCommerceItemList = [
  { id: "SERVICE", name: "SIDE_BAR_eCommerce_service", link: "/e-commerce/services" },
  { id: "DISCOUNT", name: "SIDE_BAR_eCommerce_discount", link: "/e-commerce/discount" },
  { id: "SERVICE_DESCRIPTION", name: "SIDE_BAR_eCommerce_introduction", link: "/e-commerce/introduction" },
  { id: "BANNER", name: "SIDE_BAR_eCommerce_banner", link: "/e-commerce/banner" },
  {
    id: "SERVICE_DETAIL",
    name: "SIDE_BAR_eCommerce_service_detail",
    link: "/e-commerce/service-details"
  }
]
const messageItemList = [
  { id: "SMS", name: "SIDE_BAR_message_sms", link: "/message/sms" },
  { id: "PUSH", name: "SIDE_BAR_message_push", link: "/message/push" },
  { id: "ANNOUNCEMENT", name: "SIDE_BAR_message_notice", link: "/message/notice" }
]
const systemItemList = [
  { id: "APP_VERSION", name: "SIDE_BAR_system_app", link: "/system/app-version" },
  { id: "TNC", name: "SIDE_BAR_system_terms", link: "/system/tnc" },
  { id: "FAQ", name: "SIDE_BAR_system_FAQ", link: "/system/faq" },
  { id: "CONTACT_US", name: "SIDE_BAR_system_contact", link: "/system/contact-us" },
  { id: "ABOUT_US", name: "SIDE_BAR_system_about", link: "/system/about-us" }
]
const operatorItemList = [
  { id: "ADMIN_PERMISSION", name: "SIDE_BAR_admin_type", link: "/admin/types" },
  { id: "ADMIN", name: "SIDE_BAR_admin_list", link: "/admin/list" },
  { id: "ADMIN_PASSWORD", name: "SIDE_BAR_admin_reset_password", link: "/admin/reset-password" }
]

export const sideItemList = [
  {
    id: "SIDE_BAR_order",
    name: "SIDE_BAR_order",
    src: "frame-204",
    srcChoosed: "carbon-document-blank",
    alt: "order icon",
    list: orderItemList
  },
  {
    id: "SIDE_BAR_client",
    name: "SIDE_BAR_client",
    src: "frame-97",
    srcChoosed: "carbon-document-horizontal",
    alt: "client icon",
    list: clientItemList
  },
  {
    id: "SIDE_BAR_companyClient",
    name: "SIDE_BAR_companyClient",
    src: "frame-97",
    srcChoosed: "carbon-document-horizontal",
    alt: "companyClient icon",
    list: companyClientItemList
  },
  {
    id: "SIDE_BAR_TM_user",
    name: "SIDE_BAR_TM_user",
    src: "frame-203",
    srcChoosed: "clarity-employee-group-line",
    alt: "employee icon",
    list: TMUserItemList
  },
  // {
  //   id: "SIDE_BAR_customer",
  //   name: "SIDE_BAR_customer",
  //   src: "frame-97",
  //   srcChoosed: "carbon-document-horizontal",
  //   alt: "customer icon",
  //   list: customerItemList
  // },
  {
    id: "SIDE_BAR_eCommerce",
    name: "SIDE_BAR_eCommerce",
    src: "frame-204",
    srcChoosed: "carbon-document-blank",
    alt: "eCommerce icon",
    list: eCommerceItemList
  },
  {
    id: "SIDE_BAR_message",
    name: "SIDE_BAR_message",
    src: "frame-223",
    srcChoosed: "mdi-whistle-outline",
    alt: "message icon",
    list: messageItemList
  },
  {
    id: "SIDE_BAR_system",
    name: "SIDE_BAR_system",
    src: "frame-201",
    srcChoosed: "clarity-storage-line",
    alt: "system icon",
    list: systemItemList
  },
  {
    id: "SIDE_BAR_admin",
    name: "SIDE_BAR_admin",
    src: "frame-200",
    srcChoosed: "ri-user-settings-line",
    alt: "operator icon",
    list: operatorItemList
  }
]

export const getSideBarByPath = (pathname) => {
  let result = {}

  sideItemList.some((sideItem) => {
    return sideItem.list.some((listItem) => {
      if (listItem.link !== "/") {
        if (
          pathname.includes(listItem.link) ||
          (listItem.matchArray?.length && listItem.matchArray.includes(pathname))
        ) {
          result = { sideItem, listItem }
          return true
        }
      }
    })
  })

  return result
}
