import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getCheques = (payload = {}) => {
  let params = formatPayloadToParams(payload, ["state", "customer.customerNo", "customer.name", "sort", "order"])
  return http.get("cheques", { params })
}

export const postCheques = (payload) => {
  return http.post("cheques", payload)
}

export const putChequesById = ({ chequeId, chequeInfo }) => {
  return http.put(`cheques/${chequeId}`, chequeInfo)
}
