import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getTMMiscCharges = (payload = {}) => {
  let params = formatPayloadToParams(payload, [
    "tmMiscChargeNo",
    "tmUser.id",
    "tmUser.nameZh",
    "tmUser.staffNo",
    "tmUser.phone",
    "type",
    "transactionDate.from",
    "transactionDate.to",
    "createdByAdminId",
    "sort",
    "order"
  ])
  return http.get("tm-misc-charges", { params })
}

export const postTMMiscCharges = (payload) => {
  return http.post("tm-misc-charges", payload)
}

export const deleteTMMiscChargesById = (id) => {
  return http.delete(`tm-misc-charges/${id}`)
}
