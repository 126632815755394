import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getTMPaySlips = (payload = {}) => {
  let params = formatPayloadToParams(payload, [
    "tmUser.id",
    "tmUser.staffNo",
    "tmUser.nameZh",
    "tmUser.phone",
    "month",
    "sort",
    "order"
  ])
  return http.get("tm-pay-slips", { params })
}

export const postTMPaySlips = (payload) => {
  return http.post("tm-pay-slips", payload)
}

export const postTMPaySlipsPDFById = (id) => {
  return http.post(`tm-pay-slips/${id}/pdf`)
}

export const getTMPaySlipById = (id) => {
  return http.get(`tm-users/${id}/pay-slips`)
}

export const postTMPaySlipRegenerate = () => {
  return http.post("tm-pay-slips/regenerate")
}
