import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getBanners = (payload) => {
  let params = formatPayloadToParams(payload)
  return http.get("banners", { params })
}

export const postBanner = (bannerInfo) => {
  return http.post("banners", bannerInfo)
}

export const getBannerById = (bannerId) => {
  return http.get(`banners/${bannerId}`)
}

export const putBannerById = ({ bannerId, bannerInfo }) => {
  return http.put(`banners/${bannerId}`, bannerInfo)
}

export const deleteBannerById = (bannerId) => {
  return http.delete(`banners/${bannerId}`)
}
