import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getTMJobTypes = (payload = {}) => {
  let params = formatPayloadToParams(payload)
  return http.get("tm-job-types", { params })
}

export const postTMJobType = (jobTypeInfo) => {
  return http.post("tm-job-types", jobTypeInfo)
}

export const getTMJobTypeById = (jobTypeId) => {
  return http.get(`tm-job-types/${jobTypeId}`)
}

export const putTMJobTypeById = ({ jobTypeId, jobTypeInfo }) => {
  return http.put(`tm-job-types/${jobTypeId}`, jobTypeInfo)
}
