import http from "./apiServices"

export const getFAQs = () => {
  return http.get("faqs")
}

export const postFAQ = (faqInfo) => {
  return http.post("faqs", faqInfo)
}

export const getFAQById = (faqId) => {
  return http.get(`faqs/${faqId}`)
}

export const putFAQById = ({ faqId, faqInfo }) => {
  return http.put(`faqs/${faqId}`, faqInfo)
}

export const deleteFAQById = (faqId) => {
  return http.delete(`faqs/${faqId}`)
}
