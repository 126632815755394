import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getSMS = (payload = {}) => {
  let params = formatPayloadToParams(payload, ["smsNo"])
  return http.get("sms", { params })
}

export const postSMS = (smsInfo) => {
  return http.post("sms", smsInfo)
}

export const getSMSById = (smsId) => {
  return http.get(`sms/${smsId}`)
}

export const putSMSById = ({ smsId, smsInfo }) => {
  return http.put(`sms/${smsId}`, smsInfo)
}

export const deleteSMSById = (smsId) => {
  return http.delete(`sms/${smsId}`)
}
