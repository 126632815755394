import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getOrders = (payload = {}) => {
  let params = formatPayloadToParams(payload, [
    "orderNo",
    "fulfilmentNo",
    "customerId",
    "tmUserId",
    "paymentStatus",
    "status",
    "type",
    "hasInvoice",
    "customer.name",
    "customer.phone",
    "user.name",
    "user.phone",
    "tmUser.nameZh",
    "tmUser.phone",
    "userAddress.district",
    "userAddress.partition",
    "date",
    "dateLt",
    "startDate",
    "endDate",
    "sort",
    "order"
  ])
  return http.get("orders", { params })
}

export const postOrder = (OrderInfo) => {
  return http.post("orders", OrderInfo)
}

export const getOrderById = (OrderId) => {
  return http.get(`orders/${OrderId}`)
}

export const putOrderById = ({ orderId, orderInfo }) => {
  return http.put(`orders/${orderId}`, orderInfo)
}

export const postOrderCancelById = ({ orderId, reason }) => {
  return http.post(`orders/${orderId}/cancel`, reason)
}

export const postOrderConfirmRefundById = ({ orderId }) => {
  return http.post(`orders/${orderId}/confirm-refund`)
}

export const postOrderRejectRefundById = ({ orderId }) => {
  return http.post(`orders/${orderId}/reject-refund`)
}

export const postOrderOT = ({ orderId, duration }) => {
  return http.post(`orders/${orderId}/ot`, { duration })
}

export const postOrderPricingById = (orderInfo) => {
  return http.post(`orders/pricing`, orderInfo)
}

export const postOrderCheckoutById = (payload) => {
  return http.post(`checkout`, payload)
}

export const getOrderTMsById = ({ orderId, keyword }) => {
  let params = {}
  if (keyword) params.keyword = keyword
  return http.get(`orders/${orderId}/tm-users`, { params })
}

export const postOrderReviewById = ({ orderId, reviews }) => {
  return http.post(`orders/${orderId}/reviews`, reviews)
}

export const putOrderCommentById = ({ orderId, comments }) => {
  return http.put(`orders/${orderId}/comments`, comments)
}

export const postOrderFulfilmentPdfById = ({ orderId }) => {
  return http.post(`orders/${orderId}/fulfilment-pdf`)
}

export const postCheck418 = ({ orderId, tmUserId }) => {
  return http.post(`orders/${orderId}/check-418`, { tmUserId })
}

export const getMatching = (orderId) => {
  return http.get(`orders/${orderId}/matching`)
}
