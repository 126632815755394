import apiBaseUrl from "@utils/apiBaseUrl"

export const importTemplateUrl = {
  linkedUser: {
    text: "EHS_Import_User_Template.xlsx",
    url: `${apiBaseUrl}/import/templates?type=User`
  },
  discountRule: {
    text: "EHS_Import_DiscountRule_Template.xlsx",
    url: `${apiBaseUrl}/import/templates?type=DiscountRule`
  },
  accountBalance: {
    text: "EHS_Import_DollarLog_Template.xlsx",
    url: `${apiBaseUrl}/import/templates?type=DollarLog`
  },
  tmUsers: {
    text: "EHS_Import_TMUser_Template.xlsx",
    url: `${apiBaseUrl}/import/templates?type=TMUser`
  },
  customer: {
    text: "EHS_Import_Customer_Template.xlsx",
    url: `${apiBaseUrl}/import/templates?type=Customer`
  },
  order: {
    text: "EHS_Import_Order_Template.xlsx",
    url: `${apiBaseUrl}/import/templates?type=Order`
  },
  sms: {
    text: "EHS_SMS_Target_Template.csv",
    url: `${apiBaseUrl}/sms/templates`
  },
  push: {
    text: "EHS_PushNotification_Target_Template.csv",
    url: `${apiBaseUrl}/pushNotifications/templates`
  }
}
