import isBrowser from "@utils/isBrowser"
import cookie from "cookie"

export const tokenKey = "token"

export const setCookieToken = (value) => {
  if (isBrowser) {
    document.cookie = `${tokenKey}=${value};path=/;`
  }
}

export const deleteCookieToken = () => {
  if (isBrowser) {
    document.cookie = `${tokenKey}=;path=/;expires=Sat, 20 Jan 1980 12:00:00 UTC`
  }
}

export const getCookieToken = (value) => {
  let cookieObject = {},
    token = null
  if (value) {
    cookieObject = cookie.parse(value) || {}
  } else if (isBrowser && document.cookie) {
    cookieObject = cookie.parse(document.cookie) || {}
  }
  token = cookieObject[tokenKey] || null
  return token
}
