import http from "./apiServices"

export const getUsersAll = () => {
  return http.get(`customers/users`)
}

export const getUsers = (customerId, keyword = "") => {
  let params = {}
  if (keyword) params.keyword = keyword
  return http.get(`customers/${customerId}/users`, { params })
}

export const postUser = ({ customerId, userInfo }) => {
  return http.post(`customers/${customerId}/users`, userInfo)
}

export const getUserById = ({ customerId, userId }) => {
  return http.get(`customers/${customerId}/users/${userId}`)
}

export const putUserById = ({ customerId, userId, userInfo }) => {
  return http.put(`customers/${customerId}/users/${userId}`, userInfo)
}

export const postUserAddressById = ({ customerId, userId, addresses }) => {
  return http.post(`customers/${customerId}/users/${userId}/addresses`, addresses)
}

export const getLinkableUsers = ({ customerId, keyword }) => {
  let params = {}
  if (keyword) params.keyword = keyword
  return http.get(`customers/${customerId}/linkableUsers`, { params })
}

export const postLinkUsers = ({ customerId, userIds }) => {
  return http.post(`customers/${customerId}/linkUsers`, { userIds })
}
