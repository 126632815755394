import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getCustomers = (payload = {}) => {
  let params = formatPayloadToParams(payload, [
    "keyword",
    "type",
    "eclNo",
    "customerNo",
    "state",
    "name",
    "phone",
    "sort",
    "order"
  ])
  if (payload.type) params.type = payload.type
  return http.get("customers", { params })
}

export const postCustomer = (customerInfo) => {
  return http.post("customers", customerInfo)
}

export const getCustomerById = (customerId) => {
  return http.get(`customers/${customerId}`)
}

export const putCustomerById = ({ customerId, customerInfo }) => {
  return http.put(`customers/${customerId}`, customerInfo)
}

export const getCustomerDollarBalanceById = (customerId) => {
  return http.get(`customers/${customerId}/dollarBalance`)
}

export const getCustomerPaymentsById = ({ customerId, ...rest }) => {
  let params = formatPayloadToParams(rest)
  return http.get(`customers/${customerId}/payments`, { params })
}
