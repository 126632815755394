import { styled } from "@mui/styles"

import { IconButton } from "@mui/material"
import { ImgBase } from "@components/images"

const ImgButton = styled(({ src, alt, width, ...props }) => {
  return (
    <IconButton {...props}>
      <ImgBase width={width} src={src} alt={alt} objfit="cover" />
    </IconButton>
  )
})({})

export default ImgButton
