import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getTMRankings = (payload = {}) => {
  let params = formatPayloadToParams(payload, ["tmUser.nameZh", "tmUser.staffNo", "tmUser.phone", "sort", "order"])
  return http.get("tm-rankings", { params })
}

export const getTMRankingById = (TMUserId) => {
  return http.get(`tm-rankings/${TMUserId}`)
}
