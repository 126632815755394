import "@utils/i18n"
import { t } from "i18next"
import { areaList, districtList, partitionFlatDoc } from "./country-list"
import {
  titleOptionList,
  yesNoOptionList,
  petOptionList,
  buildingTypeOptionList,
  physicalOptionList,
  mentalOptionList,
  movingOptionList,
  statusOptionList,
  funnelOptionList,
  orderStatusList
} from "./option-list"

const addressKeyList = [
  { order: 0, id: "district", label: t("TEXT_COMMON_district") },
  { order: 1, id: "partition", label: t("TEXT_COMMON_partition") },
  { order: 2, id: "estate", label: t("TEXT_COMMON_estate") },
  { order: 3, id: "street", label: t("TEXT_COMMON_street") },
  { order: 4, id: "building", label: t("TEXT_COMMON_building") },
  { order: 5, id: "block", label: t("TEXT_COMMON_block") },
  { order: 6, id: "floor", label: t("TEXT_COMMON_floor") },
  { order: 7, id: "unit", label: t("TEXT_COMMON_unit") }
]

const createdFromValueList = [
  { id: "app", label: t("OPTION_COMMON_app") },
  { id: "tm_app", label: t("OPTION_COMMON_tm_app") },
  { id: "cms", label: t("OPTION_COMMON_cms") },
  { id: "system", label: t("OPTION_COMMON_system") }
]

const matchedByValueList = [
  { id: "manual_match", label: t("OPTION_COMMON_manual_match") },
  { id: "auto_match", label: t("OPTION_COMMON_auto_match") },
  { id: "job_pool", label: t("OPTION_COMMON_job_pool") }
]

const triggeredByValueList = [
  { id: "create", label: t("OPTION_COMMON_create") },
  { id: "checkout", label: t("OPTION_COMMON_checkout") },
  { id: "reject_refund", label: t("OPTION_COMMON_reject_refund") },
  { id: "manual_match", label: t("OPTION_COMMON_manual_match") },
  { id: "auto_match", label: t("OPTION_COMMON_auto_match") },
  { id: "job_pool", label: t("OPTION_COMMON_job_pool") },
  { id: "sign_in", label: t("OPTION_COMMON_sign_in") },
  { id: "sign_out", label: t("OPTION_COMMON_sign_out") },
  { id: "cancel", label: t("OPTION_COMMON_cancel") },
  { id: "refund", label: t("OPTION_COMMON_refund") },
  { id: "confirm_refund", label: t("OPTION_COMMON_confirm_refund") }
]

export const auditLogsActionList = [
  { id: "C", label: t("TEXT_COMMON_add") },
  { id: "U", label: t("TEXT_COMMON_update") },
  { id: "D", label: t("TEXT_COMMON_delete") }
]

export const auditLogsTargetTypeList = [
  { id: "Customer", label: t("OPTION_COMMON_customer_field") },
  { id: "User", label: t("OPTION_COMMON_user_field") },
  { id: "TMUser", label: t("OPTION_COMMON_tm_user_field") },
  { id: "Order", label: t("OPTION_COMMON_new") }
]

export const auditLogsCategoryList = [
  { id: "basic", label: t("OPTION_COMMON_basic") },
  { id: "address", label: t("OPTION_COMMON_address") },
  { id: "condition", label: t("OPTION_COMMON_condition") },
  { id: "certs", label: t("OPTION_COMMON_cert") },
  { id: "bank", label: t("OPTION_COMMON_bank") },
  { id: "others", label: t("OPTION_COMMON_others") },
  { id: "rating", label: t("OPTION_COMMON_rating") },
  { id: "status", label: t("OPTION_COMMON_status") },
  { id: "new", label: t("OPTION_COMMON_new") },
  { id: "status", label: t("OPTION_COMMON_status") },
  { id: "signInAt", label: t("OPTION_COMMON_signInAt") },
  { id: "signOutAt", label: t("OPTION_COMMON_signOutAt") }
]

const auditLogsCustomerKeyDoc = {
  basic: [
    { id: "name", label: t("MODAL_CustimerProfile_name") },
    { id: "title", label: t("MODAL_CustimerProfile_title") },
    { id: "phone", label: t("MODAL_CustimerProfile_phone") },
    { id: "email", label: t("MODAL_CustimerProfile_email") },
    { id: "eclNo", label: t("MODAL_CustimerProfile_eclNo") },
    { id: "yob", label: t("MODAL_AddUser_Field_yob") },
    { id: "relationship", label: t("MODAL_AddUser_Field_relationship") }
  ]
}
const auditLogsCustomerValueDoc = { basic: [...titleOptionList] }

const auditLogsUserKeyDoc = {
  basic: [
    { order: 0, id: "name", label: t("MODAL_AddUser_Field_name") },
    { order: 1, id: "title", label: t("MODAL_AddUser_Field_title") },
    { order: 2, id: "phone", label: t("MODAL_AddUser_Field_phone") },
    { order: 3, id: "yob", label: t("MODAL_AddUser_Field_yob") },
    { order: 4, id: "relationship", label: t("MODAL_AddUser_Field_relationship") }
  ],
  address: [
    ...addressKeyList,
    { order: 8, id: "saleableArea", label: t("MODAL_AddUser_Field_area") },
    { order: 9, id: "buildingType", label: t("MODAL_AddUser_Field_buildingType") },
    { order: 10, id: "hasLift", label: t("MODAL_AddUser_Field_hasLift") },
    { order: 11, id: "hasPet", label: t("MODAL_AddUser_Field_hasPet") },
    { order: 12, id: "pet", label: t("MODAL_AddUser_Field_pet") },
    { order: 13, id: "pet_types", label: t("MODAL_AddUser_Field_pet_types") },
    { order: 14, id: "pet_isCaged", label: t("MODAL_AddUser_Field_pet_isCaged") }
  ],
  condition: [
    { order: 0, id: "weight", label: t("MODAL_AddUser_Field_weight") },
    { order: 1, id: "condition", label: t("OPTION_COMMON_condition") },
    { order: 2, id: "condition_moving", label: t("MODAL_AddUser_Field_condition_moving") },
    { order: 3, id: "condition_physical", label: t("MODAL_AddUser_Field_condition_physical") },
    { order: 4, id: "condition_mental", label: t("MODAL_AddUser_Field_condition_mental") },
    { order: 5, id: "remarks", label: t("MODAL_AddUser_Field_remarks") },
    { order: 6, id: "hasMaid", label: t("MODAL_AddUser_Field_hasMaid") },
    { order: 7, id: "hasIncontinence", label: t("MODAL_AddUser_Field_hasIncontinence") },
    { order: 8, id: "carer", label: t("MODAL_AddUser_Field_carer") },
    { order: 9, id: "carer_name", label: t("MODAL_AddUser_Field_carer_name") },
    { order: 10, id: "carer_title", label: t("MODAL_AddUser_Field_title") },
    { order: 11, id: "carer_phone", label: t("MODAL_AddUser_Field_carer_phone") },
    { order: 12, id: "carer_relationship", label: t("MODAL_AddUser_Field_relationship") }
  ]
}

const auditLogsUserValueDoc = {
  basic: [...titleOptionList],
  address: [
    ...yesNoOptionList,
    ...petOptionList,
    ...buildingTypeOptionList,
    ...areaList,
    ...districtList,
    ...partitionFlatDoc
  ],
  condition: [...yesNoOptionList, ...titleOptionList, ...physicalOptionList, ...mentalOptionList, ...movingOptionList]
}

const auditLogsTMUserKeyDoc = {
  basic: [
    { order: 0, id: "nameZh", label: t("MODAL_TMProfile_Field_name_ZH") },
    { order: 1, id: "nameEn", label: t("MODAL_TMProfile_Field_name_EN") },
    { order: 2, id: "staffNo", label: t("MODAL_TMProfile_Field_staffNo") },
    { order: 3, id: "phone", label: t("MODAL_TMProfile_Field_phone") },
    { order: 4, id: "phone2", label: t("MODAL_TMProfile_Field_other_phone") },
    { order: 5, id: "title", label: t("MODAL_TMProfile_Field_title") },
    { order: 6, id: "dob", label: t("MODAL_TMProfile_Field_date_of_birth") },
    { order: 7, id: "hkid", label: t("MODAL_TMProfile_Field_hkid") },
    { order: 8, id: "nationality", label: t("MODAL_TMProfile_Field_place_of_birth") },
    { order: 9, id: "marital", label: t("MODAL_TMProfile_Field_marital") },
    { order: 10, id: "education", label: t("MODAL_TMProfile_Field_education") },
    { order: 11, id: "email", label: t("MODAL_TMProfile_Field_email") },
    { order: 12, id: "emergencyContactName", label: t("MODAL_TMProfile_Field_emergency_name") },
    { order: 13, id: "emergencyContactRelation", label: t("MODAL_TMProfile_Field_emergency_relation") },
    { order: 14, id: "emergencyContactPhone", label: t("MODAL_TMProfile_Field_emergency_phone") },
    { order: 15, id: "profileImageUrl", label: t("MODAL_TMProfile_Field_photo") },
    { order: 16, id: "remark", label: t("MODAL_TMProfile_Field_remark") }
  ],
  address: [
    { order: 0, id: "addressDistrict", label: t("TEXT_COMMON_district") },
    { order: 1, id: "addressRegion", label: t("TEXT_COMMON_partition") },
    { order: 2, id: "addressEstate", label: t("TEXT_COMMON_estate") },
    { order: 3, id: "addressStreet", label: t("TEXT_COMMON_street") },
    { order: 4, id: "addressBuilding", label: t("TEXT_COMMON_building") },
    { order: 5, id: "addressBlock", label: t("TEXT_COMMON_block") },
    { order: 6, id: "addressFloor", label: t("TEXT_COMMON_floor") },
    { order: 7, id: "addressUnit", label: t("TEXT_COMMON_unit") }
  ],
  certs: [{ order: 0, id: "certs", label: t("MODAL_TMProfile_Field_certificate_name") }],
  bank: [
    { order: 0, id: "bankName", label: t("MODAL_TMProfile_Field_bank_name") },
    { order: 1, id: "bankAccountName", label: t("MODAL_TMProfile_Field_bank_account_name") },
    { order: 2, id: "bankAccountNumber", label: t("MODAL_TMProfile_Field_bank_account_number") }
  ],
  others: [
    { order: 0, id: "applyJobTypes", label: t("MODAL_TMProfile_Field_apply_job") },
    { order: 1, id: "joinDate", label: t("MODAL_TMProfile_Field_join_date") },
    { order: 2, id: "contractStartDate", label: t("MODAL_TMProfile_Field_contract_effective_date") },
    { order: 3, id: "contractEndDate", label: t("MODAL_TMProfile_Field_contract_expiry_date") },
    { order: 4, id: "source", label: t("MODAL_TMProfile_Field_how_kow_job") },
    { order: 5, id: "funnel", label: t("MODAL_TMProfile_Field_Funnels") }
  ],
  rating: [{ order: 0, id: "rating", label: t("OPTION_COMMON_rating") }],
  status: [{ order: 0, id: "status", label: t("MODAL_TMProfile_Field_account_status") }]
}

const auditLogsTMUserValueDoc = {
  basic: [...titleOptionList],
  address: [...buildingTypeOptionList, ...areaList, ...districtList, ...partitionFlatDoc],
  // certs: null,
  // bank: null,
  others: [...funnelOptionList],
  // rating: null,
  status: [...statusOptionList]
}
const auditLogsOrderKeyDoc = {
  new: [
    { order: 0, id: "orderNo", label: t("TABLE_orderId") },
    { order: 1, id: "createdFrom", label: t("TEXT_COMMON_createdFrom") },
    { order: 2, id: "matchedBy", label: t("TEXT_COMMON_matchedBy") }
  ],
  status: [
    { order: 0, id: "status", label: t("OPTION_COMMON_status") },
    { order: 1, id: "triggeredBy", label: t("TEXT_COMMON_triggeredBy") },
    { order: 2, id: "createdFrom", label: t("TEXT_COMMON_createdFrom") }
  ],
  signInAt: [{ order: 0, id: "signInAt", label: t("OPTION_COMMON_signInAt") }],
  signOutAt: [{ order: 0, id: "signOutAt", label: t("OPTION_COMMON_signOutAt") }]
}
const auditLogsOrderValueDoc = {
  new: [...createdFromValueList, ...matchedByValueList],
  status: [...orderStatusList, ...triggeredByValueList, ...createdFromValueList]
  // signInAt: null,
  // signOutAt: null
}
export const auditLogsDoc = {
  Customer: { key: auditLogsCustomerKeyDoc, value: auditLogsCustomerValueDoc },
  User: { key: auditLogsUserKeyDoc, value: auditLogsUserValueDoc },
  TMUser: { key: auditLogsTMUserKeyDoc, value: auditLogsTMUserValueDoc },
  Order: { key: auditLogsOrderKeyDoc, value: auditLogsOrderValueDoc }
}

export const auditLogsList = (targetType, category, type) => auditLogsDoc[targetType][type][category]
