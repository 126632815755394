import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getDeleteCustomerRequests = (payload = {}) => {
  let params = formatPayloadToParams(payload, ["customerNo", "name", "phone"])
  return http.get("deleteCustomerRequests", { params })
}

export const approveDeleteCustomerRequestsById = (requrestId) => {
  return http.get(`deleteCustomerRequests/${requrestId}/approve`)
}
