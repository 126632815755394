import { makeStyles } from "@mui/styles"
import assetPrefix from "@prefix"

const useStyles = makeStyles({
  root: {
    width: ({ width }) => width || "280px",
    height: ({ height }) => height || "auto",
    cursor: ({ cursor }) => cursor || "unset",
    objectFit: ({ objectfit }) => objectfit || "contain"
  }
})

const ImgExternal = (props) => {
  const classes = useStyles(props)
  const { src, alt, ...rest } = props

  return <img src={src} alt={alt || "external photo"} className={classes.root} {...rest} />
}

export default ImgExternal
