import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getNotices = (payload = {}) => {
  let params = formatPayloadToParams(payload, ["noticeNo"])
  return http.get("notices", { params })
}

export const postNotice = (noticeInfo) => {
  return http.post("notices", noticeInfo)
}

export const getNoticeById = (noticeId) => {
  return http.get(`notices/${noticeId}`)
}

export const putNoticeById = ({ noticeId, noticeInfo }) => {
  return http.put(`notices/${noticeId}`, noticeInfo)
}
