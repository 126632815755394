import moment from "moment"
import { districtDoc, partitionList } from "@constants"
import { isArray, isNumber, forEach } from "lodash"
import { isEmpty } from "@utils/validator"

export const dateFormat = {
  dateWithTime: "YYYY-MM-DD HH:mm",
  dateOnly: "YYYY-MM-DD",
  dateOnlyTC: "LL",
  yearOnly: "YYYY",
  monthOnly: "MMM",
  timeOnly: "LT",
  time24Only: "HH:mm"
}

export const formatInvalidateDate = (date) => {
  return date === "Invalid date" ? "-" : date
}

export function formatDateWithTime(timestamp) {
  return timestamp ? formatInvalidateDate(moment(new Date(timestamp)).format(dateFormat.dateWithTime)) : "-"
}

export function formatDateOnly(timestamp) {
  return timestamp ? formatInvalidateDate(moment(new Date(timestamp)).format(dateFormat.dateOnly)) : "-"
}

export function formatDateOnlyTC(timestamp) {
  return timestamp
    ? formatInvalidateDate(moment(new Date(timestamp)).locale("zh-hk").format(dateFormat.dateOnlyTC))
    : "-"
}

export function formatYearOnly(timestamp) {
  return timestamp ? formatInvalidateDate(moment(new Date(timestamp)).format(dateFormat.yearOnly)) : "-"
}

export function formatMonthOnly(timestamp) {
  return timestamp ? formatInvalidateDate(moment(new Date(timestamp)).format(dateFormat.monthOnly)) : "-"
}

export function formatTimeOnly(timestamp) {
  return timestamp ? formatInvalidateDate(moment(new Date(timestamp)).format(dateFormat.timeOnly).toLowerCase()) : "-"
}

export function formatTimeWithDuration(timestamp, duration) {
  let from = moment(timestamp, "hh:mm").locale("zh-hk").format("ALT").toLowerCase(),
    to = moment(timestamp, "hh:mm").locale("zh-hk").add(duration, "m").format("ALT").toLowerCase()
  return `${from}-${to}`
}

export function formatTime24Only(timestamp) {
  return timestamp ? formatInvalidateDate(moment(new Date(timestamp)).format(dateFormat.time24Only).toLowerCase()) : "-"
}

export function formatISOTime(timestamp) {
  return timestamp ? formatInvalidateDate(moment(timestamp).toISOString()) : "-"
}

export function formatApiToData(timestamp) {
  if (timestamp) {
    return new Date(...moment(new Date(timestamp)).utc().toArray())
  } else {
    return new Date(timestamp)
  }
}

export const formatPhoneToField = (phoneNumber) => {
  if (phoneNumber?.includes("-")) {
    const [countryCode, number] = phoneNumber.split("-")
    return { countryCode, number }
  }
  return { countryCode: "", number: "" }
}

export const formatPhoneToData = (state, type, data) => {
  const [countryCode, number] = state.split("-")
  if (type === "countryCode") {
    return `${data}-${number || ""}`
  } else {
    return `${countryCode || ""}-${data}`
  }
}

export const formatAddressToField = (addr) => {
  if (!addr) return ""

  let district = addr.district ? districtDoc[addr.district].label : ""
  let partition = ""

  if (addr.district && addr.partition) {
    let partitionArray = partitionList(addr.district)
    partition = partitionArray.find((item) => item.id == addr.partition)?.label || ""
  }

  const { estate, street, building, block, floor, unit } = addr

  let result = `${district}${partition}${estate}${street}${building && `${building}大廈`}${block && `${block}座數`}${
    floor && `${floor}樓`
  }${unit && `${unit}室`}`

  return result
}

export const formatTMAddressToField = ({
  addressRegion,
  addressDistrict,
  addressEstate,
  addressStreet,
  addressBuilding,
  addressBlock,
  addressFloor,
  addressUnit
}) => {
  return formatAddressToField({
    district: addressRegion,
    partition: addressDistrict,
    street: addressStreet,
    estate: addressEstate,
    building: addressBuilding,
    block: addressBlock,
    floor: addressFloor,
    unit: addressUnit
  })
}

export const formatFieldToJobTypes = (jobTypes) => {
  return isArray(jobTypes) ? jobTypes.map((type) => type.name).join(", ") : jobTypes
}

export function getFileExtension(fileUrl) {
  if (!fileUrl?.length) return null
  return fileUrl.split(/[#?]/)[0].split(".").pop().trim().toLowerCase()
}

export const convertIdToLabel = (id, list) => {
  return list.find((item) => item.id === id)?.label || id
}

export function formatFieldToPrice(price) {
  return isEmpty(price) ? "" : price / 100
}

export const formatFieldToServiceName = (service) => {
  return service?.name ? service.name : ""
}

export const formatPayloadToParams = ({ offset = 0, limit = 50, ...rest }, paramKeys = []) => {
  paramKeys.push("offset", "limit")
  let params = { offset, limit, ...rest }
  forEach(params, (val, key) => {
    if (isEmpty(val)) {
      delete params[key]
    } else if (!paramKeys.some((e) => e === key)) {
      delete params[key]
      console.log(
        `%c ${key} : ${val} `,
        `color: white; background-color: #f66361`,
        `has been removed from the query string.`
      )
    }
  })
  if (limit === 0) {
    params.limit = 10000
    delete params.offset
  } else if (offset) {
    params.offset = limit * (offset - 1)
  }
  return params
}

export const getEven = (number) => {
  if (!isNumber(number)) {
    return number
  }
  return number % 2 ? number - (number % 2) : number
}
