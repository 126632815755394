import { makeStyles } from "@mui/styles"
import assetPrefix from "@prefix"

const useStyles = makeStyles({
  root: {
    width: ({ width }) => width || "32px",
    height: ({ width, height }) => height || width || "32px",
    cursor: ({ cursor }) => cursor || "unset",
    objectFit: ({ objectfit }) => objectfit || "contain"
  }
})

const ImgBase = (props) => {
  const classes = useStyles(props)
  const { src, alt, ...rest } = props
  const srcCustom = rest.srcx ? `${rest.srcx}.png` : `${src}@2x.png`

  return <img src={`${assetPrefix}/images/${srcCustom}`} alt={alt || src} className={classes.root} {...rest} />
}

export default ImgBase
