export const customerDisplayList = [
  { label: "TABLE_account_state", show: true },
  { label: "TABLE_customerId", show: true },
  { label: "TABLE_customer_name", show: true },
  { label: "TABLE_title", show: true },
  { label: "TABLE_customer_phone", show: true },
  { label: "TABLE_userCount", show: true },
  { label: "TABLE_paring", show: true },
  { label: "TABLE_paired", show: true },
  { label: "TABLE_cancelled", show: true },
  { label: "TABLE_first_paringAt", show: true },
  { label: "TABLE_latest_paringAt", show: true },
  { label: "TABLE_latest_loginAt", show: true },
  { label: "TABLE_internal_remark", show: true },
  { label: "TABLE_account_createdAt", show: true },
  { label: "TABLE_PELS", show: false },
  { label: "TABLE_hasCCSVoucher", show: false }
]

export const customerRatingDisplayList = [
  { label: "TABLE_conclusion", show: true },
  { label: "TABLE_orderId", show: true },
  { label: "TABLE_customerId", show: true },
  { label: "TABLE_customer_name", show: true },
  { label: "TABLE_customer_phone", show: true },
  { label: "TABLE_TM_id", show: true },
  { label: "TABLE_TM_name", show: true },
  { label: "TABLE_TM_phone", show: true },
  { label: "TABLE_satisfaction", show: true },
  { label: "TABLE_service_quality", show: true },
  { label: "TABLE_service_attitude", show: true },
  { label: "TABLE_comment", show: true },
  { label: "TABLE_remark", show: true },
  { label: "TABLE_createdAt", show: true },
  { label: "TABLE_comment_record_file_id", show: false }
]

export const companyPaymentDisplayList = [
  { label: "TABLE_payment_notice_number", show: true },
  { label: "TABLE_order_number", show: true },
  { label: "TABLE_payment_status", show: true },
  { label: "TABLE_create_date_time", show: true },
  { label: "TABLE_payment_due_date", show: true },
  { label: "TABLE_customer_name", show: true },
  { label: "TABLE_customer_phone", show: true },
  { label: "TABLE_customerId", show: true }
]

export const companyChequeDisplayList = [
  { label: "TABLE_payment_status", show: true },
  { label: "TABLE_customer_number", show: true },
  { label: "TABLE_customer_name", show: true },
  { label: "TABLE_bank_name", show: true },
  { label: "TABLE_cheque_number", show: true },
  { label: "TABLE_cheque_amount", show: true },
  { label: "TABLE_create_date_time", show: true },
  { label: "TABLE_cheque_accept_reject_date", show: true },
  { label: "TABLE_remark", show: true }
]

export const companyInvoiceDisplayList = [
  { label: "TABLE_order_state", show: true },
  { label: "TABLE_orderId", show: true },
  { label: "TABLE_payment_state", show: true },
  { label: "TABLE_invoiceId", show: true },
  { label: "TABLE_user_name", show: true },
  { label: "TABLE_phone", show: true },
  { label: "TABLE_service", show: true },
  { label: "TABLE_address", show: true },
  { label: "TABLE_order_date", show: true },
  { label: "TABLE_orderAt", show: true },
  { label: "TABLE_TM_user_name", show: true },
  { label: "TABLE_fulfillment_no", show: true }
]

export const TMDisplayList = [
  { label: "TABLE_account_state", show: true },
  { label: "TABLE_TM_userId", show: true },
  { label: "TABLE_TM_user_name", show: true },
  { label: "TABLE_TM_user_phone", show: true },
  { label: "TABLE_title", show: true },
  { label: "TABLE_TM_user_team", show: true },
  { label: "TABLE_418", show: true },
  { label: "TABLE_point", show: true },
  { label: "TABLE_licensed_service", show: true },

  { label: "TABLE_address", show: false },
  { label: "TABLE_latest_finishAt", show: false },
  { label: "TABLE_latest_loginAt", show: false },
  { label: "TABLE_willing_to_serve", show: false },
  { label: "TABLE_specify_user_gender", show: false }
]

export const TMRatingDisplayList = [
  { label: "TABLE_TM_userId", show: true },
  { label: "TABLE_TM_user_name", show: true },
  { label: "TABLE_TM_user_phone", show: true },
  { label: "TABLE_service_finished", show: true },
  { label: "TABLE_scored_times", show: true },
  { label: "TABLE_overall_average", show: true },
  { label: "TABLE_score_20_average", show: true },
  { label: "TABLE_manual_score_total", show: true }
]

export const TMApplicationDisplayList = [
  { label: "TABLE_applicant_name", show: true },
  { label: "TABLE_applicant_phone", show: true },
  { label: "TABLE_apply_job", show: true },
  { label: "TABLE_apply_status", show: true },
  { label: "TABLE_create_date", show: true }
]

export const TMMiscChargeDisplayList = [
  { label: "TABLE_misc_charge_id", show: true },
  { label: "TABLE_TM_userId", show: true },
  { label: "TABLE_TM_user_name", show: true },
  { label: "TABLE_TM_user_phone", show: true },
  { label: "TABLE_amount", show: true },
  { label: "TABLE_type", show: true },
  { label: "TABLE_transaction_date", show: true },
  { label: "TABLE_remark", show: true },
  { label: "TABLE_admin", show: true },
  { label: "TABLE_createdAt", show: true }
]

export const TMSalaryDisplayList = [
  { label: "TABLE_TM_userId", show: true },
  { label: "TABLE_TM_user_name", show: true },
  { label: "TABLE_TM_user_phone", show: true },
  { label: "TABLE_date", show: true },
  { label: "TABLE_salary", show: true },
  { label: "TABLE_net_pay", show: true },
  { label: "TABLE_emp_eeman", show: false },
  { label: "TABLE_emp_eevol", show: false },
  { label: "TABLE_emp_erman", show: false },
  { label: "TABLE_emp_ervol", show: false }
]

export const orderDisplayList = [
  { label: "TABLE_order_state", show: true },
  { label: "TABLE_orderId", show: true },
  { label: "TABLE_customer_name", show: true },
  { label: "TABLE_customer_phone", show: true },
  { label: "TABLE_user_name", show: true },
  { label: "TABLE_user_phone", show: true },
  { label: "TABLE_service_type", show: true },
  { label: "TABLE_service_sub_type", show: true },
  { label: "TABLE_service_address", show: true },
  { label: "TABLE_service_area", show: true },
  { label: "TABLE_service_partition", show: true },
  { label: "TABLE_order_time", show: true },
  { label: "TABLE_service_duration", show: true },
  { label: "TABLE_order_remark", show: true },
  { label: "TABLE_TM_user_name", show: true },
  { label: "TABLE_TM_user_phone", show: true },
  { label: "TABLE_service_money", show: true },
  { label: "TABLE_payment_state", show: true },
  { label: "TABLE_createdAt", show: true },
  { label: "TABLE_418_status", show: true },
  { label: "TABLE_fulfilment_no", show: false },
  { label: "TABLE_paringAt", show: false },
  { label: "TABLE_service_payment", show: false },
  { label: "TABLE_satisfaction", show: false },
  { label: "TABLE_service_quality", show: false },
  { label: "TABLE_service_attitude", show: false },
  { label: "TABLE_sign_in_date_time", show: false },
  { label: "TABLE_sign_out_date_time", show: false }
]

export const serviceDisplayList = [
  { label: "TABLE_service_no", show: true },
  { label: "TABLE_service_type", show: true },
  { label: "TABLE_service_name", show: true },
  { label: "TABLE_launch", show: true },
  { label: "TABLE_service_level", show: true },
  { label: "TABLE_TM_user_type", show: true },
  { label: "TABLE_latest_updatedAt", show: true },
  { label: "TABLE_createdAt", show: true }
]

export const discountDisplayList = [
  { label: "TABLE_discount_code", show: true },
  { label: "TABLE_discount_name", show: true },
  { label: "TABLE_discount_person", show: true },
  { label: "TABLE_state", show: true },
  { label: "TABLE_start_time", show: true },
  { label: "TABLE_end_time", show: true },
  { label: "TABLE_type", show: true },
  { label: "TABLE_remain_available_count", show: true },
  { label: "TABLE_remark", show: true },
  { label: "TABLE_latest_updatedAt", show: true },
  { label: "TABLE_account_type", show: true }
]

export const bannerDisplayList = [
  { label: "TABLE_banner_name", show: true },
  { label: "TABLE_banner_position", show: true },
  { label: "TABLE_start_time", show: true },
  { label: "TABLE_end_time", show: true }
]

export const adminDisplayList = [
  { label: "TABLE_admin_login_name", show: true },
  { label: "TABLE_admin_display_name", show: true },
  { label: "TABLE_admin_category", show: true },
  { label: "TABLE_state", show: true },
  { label: "TABLE_createdAt", show: true }
]

export const TMTypeDisplayList = [
  { label: "TABLE_TM_user_type", show: true },
  { label: "TABLE_state", show: true }
]

export const TMReportDisplayList = [
  { label: "TABLE_submission_time", show: true },
  { label: "TABLE_submission_status", show: true },
  { label: "TABLE_TM_userId", show: true },
  { label: "TABLE_TM_user_name", show: true },
  { label: "TABLE_TM_user_phone", show: true },
  { label: "TABLE_start_date", show: true },
  { label: "TABLE_start_time", show: true },
  { label: "TABLE_end_time", show: true },
  { label: "TABLE_TM_user_team", show: true },
  { label: "TABLE_418", show: true },
  { label: "TABLE_licensed_service", show: true }
]

export const adminTypeDisplayList = [
  { label: "TABLE_admin_category", show: true },
  { label: "TABLE_createdAt", show: true },
  { label: "TABLE_latest_updatedAt", show: true }
]

export const accountBalanceDisplayList = (isCustomerPage) => {
  let list = [
    { label: "TABLE_customerId", show: true },
    { label: "TABLE_customer_name", show: true },
    { label: "TABLE_customer_phone", show: true },
    { label: "TABLE_trade_type", show: true },
    { label: "TABLE_cash", show: true },
    { label: "TABLE_latest_updatedAt", show: true },
    { label: "TABLE_remark", show: true },
    { label: "TABLE_orderId", show: true }
  ]

  if (!isCustomerPage) list.push({ label: "TABLE_payment_notice_number", show: true })

  return list
}
export const tmRemarkDisplayList = [
  { label: "TABLE_orderId", show: true },
  { label: "TABLE_customer_name", show: true },
  { label: "TABLE_user_name", show: true },
  { label: "TABLE_remark", show: true },
  { label: "TABLE_editAt", show: true },
  { label: "TABLE_TM_user_name", show: true }
]

export const paymentListDisplayList = [
  { label: "TABLE_order_create_date_time", show: true },
  { label: "TABLE_customer_number", show: true },
  { label: "TABLE_invoice_number", show: true },
  { label: "TABLE_cheque_number", show: true },
  { label: "TABLE_type", show: true },
  { label: "TABLE_payment_method", show: true },
  { label: "TABLE_amount", show: true },
  { label: "TABLE_orderId", show: true },
  { label: "TABLE_fulfillment_no", show: true }
]

export const smsDisplayList = [
  { label: "TABLE_SMS_id", show: true },
  { label: "TABLE_create_by", show: true },
  { label: "TABLE_state", show: true },
  { label: "TABLE_send_date_time", show: true },
  { label: "TABLE_receiver", show: true },
  { label: "TABLE_content", show: true }
]

export const messageDisplayList = [
  { label: "TABLE_push_id", show: true },
  { label: "TABLE_create_by", show: true },
  { label: "TABLE_state", show: true },
  { label: "TABLE_send_date_time", show: true },
  { label: "TABLE_receiver", show: true },
  { label: "TABLE_content", show: true },
  { label: "TABLE_read", show: true }
]

export const noticeDisplayList = [
  { label: "TABLE_notice_id", show: true },
  { label: "TABLE_create_by", show: true },
  { label: "TABLE_state", show: true },
  { label: "TABLE_start_time", show: true },
  { label: "TABLE_end_time", show: true },
  { label: "TABLE_receiver", show: true },
  { label: "TABLE_read", show: true }
]

export const deleteAccountDisplayList = [
  { label: "TABLE_customerId", show: true },
  { label: "TABLE_customer_name", show: true },
  { label: "TABLE_customer_phone", show: true }
]

export const approve418DisplayList = [
  { label: "TABLE_TM_userId", show: true },
  { label: "TABLE_TM_user_name", show: true },
  { label: "TABLE_orderId", show: true },
  { label: "TABLE_required_sender", show: true },
  { label: "TABLE_requiredAt", show: true },
  { label: "TABLE_state", show: true }
]

export const TMDetailRatingDisplayList = [
  { label: "TABLE_orderId", show: true },
  { label: "TABLE_overall", show: true },
  { label: "TABLE_skill", show: true },
  { label: "TABLE_attitude", show: true },
  { label: "TABLE_score_date", show: true },
  { label: "TABLE_conclusion", show: true }
]
