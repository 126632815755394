import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getServices = (payload = {}) => {
  let params = formatPayloadToParams(payload, [
    "level",
    "serviceNo",
    "name",
    "tmJobTypeIds",
    "type",
    "nameOnly",
    "orderType"
  ])
  return http.get("services", { params })
}

export const postService = (serviceInfo) => {
  return http.post("services", serviceInfo)
}

export const getServiceById = (serviceId) => {
  return http.get(`services/${serviceId}`)
}

export const putServiceById = ({ serviceId, serviceInfo }) => {
  return http.put(`services/${serviceId}`, serviceInfo)
}
