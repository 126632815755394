import http from "./apiServices"

export const getServiceDetails = () => {
  return http.get("serviceDetails")
}

export const postServiceDetails = (detailList) => {
  return http.post("serviceDetails", detailList)
}

export const deleteServiceDetails = (detailList) => {
  return http.delete(`serviceDetails`, { data: detailList })
}
