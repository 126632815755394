import http from "./apiServices"

export const getBlackLists = (params) => {
  return http.get("blacklists", { params })
}

export const postBlackLists = (payload) => {
  return http.post("blacklists", payload)
}

export const deleteBlackListsById = ({ blackId }) => {
  return http.delete(`blacklists/${blackId}`)
}
