import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getDiscountRules = (payload = {}) => {
  let params = formatPayloadToParams(payload, [
    "discountRuleNo",
    "name",
    "type",
    "remarks",
    "target",
    "quotaLeft",
    "notBefore",
    "notAfter",
    "sort",
    "order"
  ])
  return http.get("discountRules", { params })
}

export const postDiscountRule = (discountRuleInfo) => {
  return http.post("discountRules", discountRuleInfo)
}

export const getDiscountRuleById = (discountRuleId) => {
  return http.get(`discountRules/${discountRuleId}`)
}

export const putDiscountRuleById = ({ discountRuleId, discountRuleInfo }) => {
  return http.put(`discountRules/${discountRuleId}`, discountRuleInfo)
}
