import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getInvoices = (payload = {}) => {
  let params = formatPayloadToParams(payload, [
    "invoiceNo",
    "state",
    "customer.customerNo",
    "customer.name",
    "customer.phone",
    "sort",
    "order"
  ])
  return http.get("invoices", { params })
}

export const postInvoices = (invoiceInfo) => {
  return http.post("invoices", invoiceInfo)
}

export const getInvoicesById = (invoiceId) => {
  return http.get(`invoices/${invoiceId}`)
}

export const putInvoicesById = ({ invoiceId, invoiceInfo }) => {
  return http.put(`invoices/${invoiceId}`, invoiceInfo)
}

export const postInvoicePdfById = ({ invoiceId }) => {
  return http.post(`invoices/${invoiceId}/pdf`)
}
