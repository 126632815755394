import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getPushNotifications = (payload = {}) => {
  let params = formatPayloadToParams(payload, ["pushNotificationNo"])
  return http.get("pushNotifications", { params })
}

export const postPushNotification = (pushInfo) => {
  return http.post("pushNotifications", pushInfo)
}

export const getPushNotificationById = (pushId) => {
  return http.get(`pushNotifications/${pushId}`)
}

export const putPushNotificationById = ({ pushId, pushInfo }) => {
  return http.put(`pushNotifications/${pushId}`, pushInfo)
}

export const deletePushNotificationById = (pushId) => {
  return http.delete(`pushNotifications/${pushId}`)
}
