import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getTMRosters = (payload = {}) => {
  let params = formatPayloadToParams(payload, [
    "tmUserId",
    "nameZh",
    "phone",
    "staffNo",
    "date",
    "fromDate",
    "toDate",
    "approvedJobTypeId",
    "group",
    "group418",
    "includeDeleted",
    "sort",
    "order"
  ])
  return http.get("tm-rosters", { params })
}

export const postTMRoster = (payload) => {
  return http.post("tm-rosters", payload)
}
