import http from "./apiServices"

export const getExportCustomers = (params) => {
  return http.get("export/customers", { params })
}

export const getExportUsers = (params) => {
  return http.get("export/users", { params })
}

export const getExportInvoices = (params) => {
  return http.get("export/invoices", { params })
}

export const getExportCheques = (params) => {
  return http.get("export/cheques", { params })
}

export const getExportTMUsers = (params) => {
  return http.get("export/tmUsers", { params })
}

export const getExportTMRosters = (params) => {
  return http.get("export/tmRosters", { params })
}

export const getExportTMRemarks = (params) => {
  return http.get("export/tmRemarks", { params })
}

export const getExportTMApplications = (params) => {
  return http.get("export/tmApplications", { params })
}

export const getExportTM418Requests = (params) => {
  return http.get("export/tm418Requests", { params })
}

export const getExportServices = (params) => {
  return http.get("export/services", { params })
}

export const getExportDollarLogs = (params) => {
  return http.get("export/dollarLogs", { params })
}

export const getExportReviews = (params) => {
  return http.get("export/reviews", { params })
}

export const getExportBlacklists = (params) => {
  return http.get("export/blacklists", { params })
}

export const getExportDiscountRules = (params) => {
  return http.get("export/discountRules", { params })
}

export const getExportSMS = (params) => {
  return http.get("export/sms", { params })
}

export const getExportPushNotifications = (params) => {
  return http.get("export/pushNotifications", { params })
}

export const getExportNotices = (params) => {
  return http.get("export/notices", { params })
}

export const getExportFaqs = (params) => {
  return http.get("export/faqs", { params })
}

export const getExportOrders = (params) => {
  return http.get("export/orders", { params })
}

export const getExportTMMiscCharges = (params) => {
  return http.get("export/tmMiscCharges", { params })
}
