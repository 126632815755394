import http from "./apiServices"

export const getImportTemplates = (params) => {
  return http.get("import/templates", {
    responseType: "arraybuffer",
    params
  })
}

export const postImportUsers = (file, params) => {
  return http.post("import/users", file, {
    headers: { "Content-Type": "multipart/form-data" },
    params
  })
}

export const postImportDiscounts = (file, params) => {
  return http.post("import/discountRules", file, {
    headers: { "Content-Type": "multipart/form-data" },
    params
  })
}

export const postImportDollarLogs = (file, params) => {
  return http.post("import/dollarLogs", file, {
    headers: { "Content-Type": "multipart/form-data" },
    params
  })
}

export const postImportTMUsers = (file, params) => {
  return http.post("import/tmUsers", file, {
    headers: { "Content-Type": "multipart/form-data" },
    params
  })
}

export const postImportCustomers = (file, params) => {
  return http.post("import/customers", file, {
    headers: { "Content-Type": "multipart/form-data" },
    params
  })
}
export const postImportOrders = (file, params) => {
  return http.post("import/orders", file, {
    headers: { "Content-Type": "multipart/form-data" },
    params
  })
}
