import { formatPhoneToField } from "./formatter"
import { typeOptionDoc } from "@constants"

export const isEmpty = (value) => {
  return value === null || value === undefined || value === "" || value.length === 0
}

export const isIndividual = (type) => {
  return type === typeOptionDoc.individual.id
}

export const invalidatePassword = (password) => {
  let regex = /().{8,}/
  return !regex.test(password)
}

export const isInvalidDigits = (str) => {
  let regex = /^[0-9]+$/
  return !regex.test(str)
}

export const isInvalidPhoneDigits = (str) => {
  let regex = /^[0-9-]+$/
  return !regex.test(str)
}

export const isInvalidPhone = (phone, checkLength = false) => {
  let phoneObj = formatPhoneToField(phone)

  if (phoneObj.countryCode == "" || phoneObj.number == "") {
    return true
  }
  if (isInvalidPhoneDigits(phoneObj.number)) {
    return true
  }

  let invalid = false
  if (checkLength) {
    let numberLength = phoneObj.number.length

    switch (phoneObj.countryCode) {
      case "852":
        invalid = numberLength !== 8
        break
      case "86":
        invalid = numberLength !== 11
        break
    }
  }

  return invalid
}

export const invalidateEmail = (email) => {
  let regex = /\S+@\S+\.\S+/
  return !regex.test(email)
}

export const invalidateTime = (time) => {
  let regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
  return !regex.test(time)
}
