import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getDeleteTMUserRequests = (payload = {}) => {
  let params = formatPayloadToParams(payload, ["staffNo", "name", "phone"])
  return http.get("deleteTMUserRequests", { params })
}

export const approveDeleteTMUserRequestsById = (requrestId) => {
  return http.get(`deleteTMUserRequests/${requrestId}/approve`)
}
