import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getAdmins = (payload = {}) => {
  let params = formatPayloadToParams(payload, ["name", "email"])
  return http.get("admins", { params })
}

export const postAdmin = (adminInfo) => {
  return http.post("admins", adminInfo)
}

export const getAdminById = (adminId = "me") => {
  return http.get(`admins/${adminId}`)
}

export const putAdminById = ({ adminId = "me", t = "", adminInfo }) => {
  return http.put(`admins/${adminId}${t && `?t=${t}`}`, adminInfo)
}

export const deleteAdminById = (adminId) => {
  return http.delete(`admins/${adminId}`)
}
