import { styled } from "@mui/styles"
import { forwardRef } from "react"
import Box from "@mui/material/Box"

import MuiLink from "@mui/material/Link"
import NextLink from "next/link"

export const VSpace = styled("div")(({ length }) => ({
  flexShrink: 0,
  height: `${length}px`,
  minHeight: `${length}px`
}))

export const HSpace = styled("div")(({ length }) => ({
  flexShrink: 0,
  width: `${length}px`,
  minWidth: `${length}px`
}))

export const Divider = styled("div")(({ theme, depth, color }) => ({
  width: "100%",
  flexShrink: 0,
  height: depth ? `${depth}px` : "1px",
  backgroundColor: theme.customDivider[color || "gray"]
}))

export const Row = styled(Box)(({ align }) => ({
  display: "flex",
  position: "relative",
  alignItems: align || "center"
}))

export const Column = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  flexDirection: "column"
}))

export const Text = styled("p")(
  ({
    theme,
    display,
    margin,
    cursor,
    width,
    textalign,
    size,
    weight,
    minwidth,
    color,
    textDecoration,
    wordbreak,
    whitespace,
    textoverflow,
    overflow
  }) => ({
    display: display || "unset",
    margin: margin || "0px",
    cursor: cursor || "unset",
    width: width || "unset",
    textAlign: textalign || "unset",
    fontSize: size || "14px",
    fontWeight: weight || "300",
    minWidth: minwidth || "unset",
    color: theme.palette.text[color || "primary"],
    textDecoration: textDecoration || "unset",
    wordBreak: wordbreak || "normal",
    whiteSpace: whitespace || "normal",
    textOverflow: textoverflow || "normal",
    overflow: overflow || "normal"
  })
)

export const FieldLabel = styled("p")(({ theme, size, weight, required }) => {
  let style = {
    fontSize: size || "16px",
    fontWeight: weight || "400",
    margin: "0 0 5px 0",
    color: theme.palette.text.black,
    whiteSpace: "nowrap"
  }
  if (required) {
    style["&::after"] = {
      content: "'*'",
      color: theme.palette.text.red
    }
  } else {
    style["&::after"] = {
      content: ""
    }
  }
  return style
})

const LinkBase = styled(MuiLink)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "500",
  // whiteSpace: "nowrap",
  color: theme.palette.info.light
}))

export const Link = forwardRef(({ href = "", as, prefetch, ...props }, ref) => {
  return (
    <NextLink href={href} as={as} prefetch={prefetch} passHref>
      <LinkBase underline="none" ref={ref} {...props} />
    </NextLink>
  )
})
