import http from "./apiServices"
import appENV from "@utils/appENV"

export const postLogin = (payload) => {
  return http.post("auth/login", payload)
}

export const getLogout = () => {
  return http.get(`/auth/logout`)
}

export const getForgetPassword = ({ email }) => {
  let params = { email }
  // test = true will not send email, url will display in response body, for local
  if (appENV === "local") {
    params.test = true
  }
  return http.get(`/auth/forgetPassword`, { params })
}

export const postOTP = (payload) => {
  return http.post("auth/otp", payload)
}
