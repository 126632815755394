import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getAdminTypes = (payload = {}) => {
  let params = formatPayloadToParams(payload)
  return http.get("adminTypes", { params })
}

export const postAdminTypes = (adminTypeInfo) => {
  return http.post("adminTypes", adminTypeInfo)
}

export const getAdminTypesById = (adminTypeId) => {
  return http.get(`adminTypes/${adminTypeId}`)
}

export const putAdminTypesById = ({ adminTypeId, adminTypeInfo }) => {
  return http.put(`adminTypes/${adminTypeId}`, adminTypeInfo)
}
