import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getTMRatings = (payload = {}) => {
  let params = formatPayloadToParams(payload, ["tmUserId"])
  return http.get("tm-ratings", { params })
}

export const postTMRating = (TMRatingInfo) => {
  return http.post(`tm-ratings`, TMRatingInfo)
}

export const postTMRatingCancelById = (TMRatingId) => {
  return http.post(`tm-ratings/${TMRatingId}/cancel`)
}
