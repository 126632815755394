import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getTMUsers = (payload = {}) => {
  let params = formatPayloadToParams(payload, [
    "keyword",
    "staffNo",
    "nameZh",
    "phone",
    "title",
    "address",
    "status",
    "applyStatus",
    "assignedGroup",
    "assignedGroup418",
    "approvedJobTypeIds",
    "activeJobTypeIds",
    "sort",
    "order"
  ])
  return http.get("tm-users", { params })
}

export const postTMUser = (TMUserInfo) => {
  return http.post("tm-users", TMUserInfo)
}

export const getTMUserById = (TMUserId) => {
  return http.get(`tm-users/${TMUserId}`)
}

export const putTMUserById = ({ TMUserId, TMUserInfo }) => {
  return http.put(`tm-users/${TMUserId}`, TMUserInfo)
}

export const getTMPreferences = (TMUserId) => {
  return http.get(`tm-users/${TMUserId}/preferences`)
}

export const putTMPreferences = ({ TMUserId, TMPreferencesInfo }) => {
  return http.put(`tm-users/${TMUserId}/preferences`, TMPreferencesInfo)
}

export const getTMGroups = (TMUserId) => {
  return http.get(`tm-users/${TMUserId}/groups`)
}

export const putTMGroup = ({ TMUserId, groupInfo }) => {
  return http.put(`tm-users/${TMUserId}/groups`, groupInfo)
}
