import "@utils/i18n"
import { t } from "i18next"

export const yesNoOptionList = [
  { id: true, label: t("OPTION_COMMON_yes") },
  { id: false, label: t("OPTION_COMMON_no") }
]

export const petOptionList = [
  { id: "cat", label: t("OPTION_COMMON_cat") },
  { id: "small dog", label: t("OPTION_COMMON_small_dog") },
  { id: "big dog", label: t("OPTION_COMMON_big_dog") },
  { id: "other", label: t("OPTION_COMMON_other_pet") }
]

export const titleOptionList = [
  { id: "mr", label: t("OPTION_COMMON_mr") },
  { id: "ms", label: t("OPTION_COMMON_ms") },
  { id: "mrs", label: t("OPTION_COMMON_mrs") }
]

export const TC_titleOptionList = [
  { id: "先生", label: t("OPTION_COMMON_mr") },
  { id: "小姐", label: t("OPTION_COMMON_ms") },
  { id: "女士", label: t("OPTION_COMMON_mrs") }
]

export const movingOptionList = [
  { id: "normal", label: t("OPTION_COMMON_normal") },
  { id: "crutch", label: t("OPTION_COMMON_crutch") },
  { id: "walker frame", label: t("OPTION_COMMON_walker_frame") },
  { id: "wheelchair", label: t("OPTION_COMMON_wheelchair") },
  { id: "bedridden", label: t("OPTION_COMMON_bedridden") }
]

export const physicalOptionList = [
  { id: "normal", label: t("OPTION_COMMON_normal") },
  { id: "parkinson's (early)", label: t("OPTION_COMMON_parkinson_early") },
  { id: "parkinson's (middle)", label: t("OPTION_COMMON_parkinson_middle") },
  { id: "parkinson's (late)", label: t("OPTION_COMMON_parkinson_late") },
  { id: "stroke (left side)", label: t("OPTION_COMMON_stroke_left_side") },
  { id: "stroke (right side)", label: t("OPTION_COMMON_stroke_right_side") },
  { id: "stroke (whole body)", label: t("OPTION_COMMON_stroke_whole_body") }
]

export const mentalOptionList = [
  { id: "normal", label: t("OPTION_COMMON_normal") },
  { id: "simple", label: t("OPTION_COMMON_simple") },
  { id: "auditory hallucination", label: t("OPTION_COMMON_auditory_hallucination") },
  { id: "uncontrollable", label: t("OPTION_COMMON_uncontrollable") },
  { id: "aggressive", label: t("OPTION_COMMON_aggressive") }
]

export const buildingTypeOptionList = [
  { id: "tong lau", label: t("OPTION_COMMON_tong_lau") },
  { id: "village house", label: t("OPTION_COMMON_village_house") },
  { id: "other", label: t("OPTION_COMMON_other") }
]

export const phoneOptionList = [
  { id: "852", label: "852" },
  { id: "86", label: "86" }
]

export const typeOptionList = [
  { id: "individual", label: t("OPTION_COMMON_individual") },
  { id: "organization", label: t("OPTION_COMMON_organization") }
]

export const typeOptionDoc = typeOptionList.reduce((obj, currnt) => ({ ...obj, [currnt.id]: currnt }), {})

export const statusOptionList = [
  { id: "Draft", label: t("OPTION_COMMON_Draft") },
  { id: "Approved", label: t("OPTION_COMMON_Approved") },
  { id: "Rejected", label: t("OPTION_COMMON_Rejected") },
  { id: "Active", label: t("OPTION_COMMON_Active") },
  { id: "Inactive", label: t("OPTION_COMMON_Inactive") },
  { id: "Terminated", label: t("OPTION_COMMON_Terminated") }
]

export const statusOptionDoc = statusOptionList.reduce((obj, currnt) => ({ ...obj, [currnt.id]: currnt }), {})

export const stateOptionList = [
  { id: "active", label: t("OPTION_COMMON_Active") },
  { id: "inactive", label: t("OPTION_COMMON_Inactive") }
  // { id: "deleted", label: t("OPTION_COMMON_Deleted") }
]

export const customerStateOptionList = [
  { id: "active", label: t("OPTION_COMMON_Account_State_Active") },
  { id: "inactive", label: t("OPTION_COMMON_Account_State_Inactive") },
  { id: "deleted", label: t("OPTION_COMMON_Account_State_Deleted") }
]

export const funnelOptionList = [
  { id: "App", label: t("OPTION_COMMON_App") },
  { id: "Job Fair", label: t("OPTION_COMMON_Job_Fair") },
  { id: "WhatsApp", label: t("OPTION_COMMON_WhatsApp") },
  { id: "Email", label: t("OPTION_COMMON_Email") },
  { id: "ERB Class", label: t("OPTION_COMMON_ERB_Class") },
  { id: "Phone Call", label: t("OPTION_COMMON_Phone_Call") },
  { id: "HR Referral", label: t("OPTION_COMMON_HR_Referral") }
]
export const servicesTypeOptionList = [
  { id: "escort", label: t("OPTION_COMMON_escort") },
  { id: "care", label: t("OPTION_COMMON_care") },
  { id: "cleaning", label: t("OPTION_COMMON_cleaning") },
  { id: "rehab", label: t("OPTION_COMMON_rehab") }
]

export const servicesTypeOptionDoc = servicesTypeOptionList.reduce(
  (obj, currnt) => ({ ...obj, [currnt.id]: currnt }),
  {}
)

export const launchOptionList = [
  { id: "published", label: t("OPTION_COMMON_launch") },
  { id: "hidden", label: t("OPTION_COMMON_dislaunch") }
]

export const launchOptionDoc = launchOptionList.reduce((obj, currnt) => ({ ...obj, [currnt.id]: currnt }), {})

export const levelOptionList = [
  { id: 1, label: t("OPTION_COMMON_level_1") },
  { id: 2, label: t("OPTION_COMMON_level_2") },
  { id: 3, label: t("OPTION_COMMON_level_3") }
]

export const transportationOptionList = [
  { id: "foot", label: t("OPTION_COMMON_foot") },
  { id: "bus", label: t("OPTION_COMMON_bus") },
  { id: "van", label: t("OPTION_COMMON_van") },
  { id: "ferry", label: t("OPTION_COMMON_ferry") },
  { id: "mtr", label: t("OPTION_COMMON_mtr") },
  { id: "taxi", label: t("OPTION_COMMON_taxi") },
  { id: "rehabBus", label: t("OPTION_COMMON_rehabBus") },
  { id: "tram", label: t("OPTION_COMMON_tram") },
  { id: "ambulance", label: t("OPTION_COMMON_ambulance") },
  { id: "nursingBus", label: t("OPTION_COMMON_nursingBus") }
]

export const visitOptionList = [
  { id: "visit", label: "在此就診" },
  { id: "other", label: "其他事項" }
]

export const visitOptionDoc = visitOptionList.reduce((obj, currnt) => ({ ...obj, [currnt.id]: currnt }), {})

export const TC_interviewStatusOptionList = [
  { id: "待跟進", label: t("OPTION_COMMON_to_be_followed_up") },
  { id: "無相關證書", label: t("OPTION_COMMON_no_relevant_certificate") },
  { id: "通過電話面試", label: t("OPTION_COMMON_interview_by_phone") },
  { id: "不通過電話面試", label: t("OPTION_COMMON_no_interview_by_phone") },
  { id: "通過第二輪面試", label: t("OPTION_COMMON_pass_second_round") },
  { id: "不通過第二輪面試", label: t("OPTION_COMMON_no_pass_second_round") },
  { id: "跟進完畢", label: t("OPTION_COMMON_follow_up") }
]

export const TC_applyStatusOptionList = [
  { id: "未完成", label: t("OPTION_COMMON_not_finish") },
  { id: "已填妥", label: t("OPTION_COMMON_completed") },
  { id: "跟進中", label: t("OPTION_COMMON_following") },
  { id: "待入職", label: t("OPTION_COMMON_wait_onboard") },
  { id: "不取錄", label: t("OPTION_COMMON_no_admitted") },
  { id: "放棄申請", label: t("OPTION_COMMON_abandon_apply") }
]

export const TC_educationOptionList = [
  { id: "小學", label: t("OPTION_COMMON_below_elementary_school") },
  { id: "中學", label: t("OPTION_COMMON_middle_school") },
  { id: "高級文憑/副學士", label: t("OPTION_COMMON_matriculation") },
  { id: "學士", label: t("OPTION_COMMON_university") },
  { id: "碩士或以上", label: t("OPTION_COMMON_education_other") }
]

export const TC_maritalOptionList = [
  { id: "已婚", label: t("OPTION_COMMON_married") },
  { id: "未婚", label: t("OPTION_COMMON_single") }
]

export const publishedOptionList = [
  { id: true, label: t("OPTION_COMMON_published") },
  { id: false, label: t("OPTION_COMMON_unpublished") }
]

export const userGenderOptionList = [
  { id: "male", label: t("OPTION_COMMON_male_user") },
  { id: "female", label: t("OPTION_COMMON_female_user") }
]

export const genderOptionList = [
  { id: "M", label: t("OPTION_COMMON_male_user") },
  { id: "F", label: t("OPTION_COMMON_female_user") }
]

export const buildingTypeCamelIdOptionList = [
  { id: "tongLau", label: t("OPTION_COMMON_tong_lau") },
  { id: "villageHouse", label: t("OPTION_COMMON_village_house") },
  { id: "other", label: t("OPTION_COMMON_other") }
]

export const petCamelIdOptionList = [
  { id: "cat", label: t("OPTION_COMMON_cat") },
  { id: "smallDog", label: t("OPTION_COMMON_small_dog") },
  { id: "bigDog", label: t("OPTION_COMMON_big_dog") },
  { id: "other", label: t("OPTION_COMMON_other_pet") }
]

export const acceptOptionList = [
  { id: true, label: t("OPTION_COMMON_accept") },
  { id: false, label: t("OPTION_COMMON_unaccept") }
]

export const careOptionList = [
  { id: "1", label: t("OPTION_COMMON_shower") },
  { id: "2", label: t("OPTION_COMMON_toilet") },
  { id: "3", label: t("OPTION_COMMON_diapers") },
  { id: "4", label: t("OPTION_COMMON_housework") },
  { id: "5", label: t("OPTION_COMMON_cooking") }
]

export const unableClearnOptionList = [
  { id: "6", label: t("OPTION_COMMON_clean_high_position") },
  { id: "7", label: t("OPTION_COMMON_cook") }
]

export const canOptionList = [
  { id: true, label: t("OPTION_COMMON_can") },
  { id: false, label: t("OPTION_COMMON_can_not") }
]

export const parkinsonOptionList = [
  { id: "early", label: t("OPTION_COMMON_parkinson_early") },
  { id: "middle", label: t("OPTION_COMMON_parkinson_middle") },
  { id: "late", label: t("OPTION_COMMON_parkinson_late") }
]

export const strokeOptionList = [
  { id: "left", label: t("OPTION_COMMON_stroke_left_side") },
  { id: "right", label: t("OPTION_COMMON_stroke_right_side") },
  { id: "whole", label: t("OPTION_COMMON_stroke_whole_body") }
]

export const otherTmTypeNoVipOptionList = [{ id: "normal", label: t("OPTION_COMMON_tmType_normal") }]

export const otherTmTypeOptionList = [
  { id: "normal", label: t("OPTION_COMMON_tmType_normal") },
  { id: "vip", label: t("OPTION_COMMON_tmType_vip") }
]

export const rehabTmTypeOptionList = [
  { id: "first_time", label: t("OPTION_COMMON_tmType_first_time") },
  // { id: "therapist", label: t("OPTION_COMMON_tmType_therapist") },
  // { id: "assistant", label: t("OPTION_COMMON_tmType_assistant") },
  { id: "reassessment", label: t("OPTION_COMMON_tmType_reassessment") }
]

export const tmGenderRequiredOptionList = [
  { id: "null", label: t("OPTION_COMMON_gender_required_all") },
  { id: "M", label: t("OPTION_COMMON_gender_required_male") },
  { id: "F", label: t("OPTION_COMMON_gender_required_female") }
]

export const tmGenderOptionList = [
  { id: "null", label: t("OPTION_COMMON_gender_all") },
  { id: "M", label: t("OPTION_COMMON_gender_male") },
  { id: "F", label: t("OPTION_COMMON_gender_female") }
]

export const groupOptionList = [
  { id: "1", label: t("OPTION_COMMON_team_1") },
  { id: "2", label: t("OPTION_COMMON_team_2") },
  { id: "3", label: t("OPTION_COMMON_team_3") }
]

export const group418OptionList = [
  { id: "A", label: t("OPTION_COMMON_418_A") },
  { id: "B", label: t("OPTION_COMMON_418_B") },
  { id: "C", label: t("OPTION_COMMON_418_C") },
  { id: "D", label: t("OPTION_COMMON_418_D") }
]

export const opinionOptionList = [
  { id: "投訴", label: t("OPTION_COMMON_complaint") },
  { id: "讚賞", label: t("OPTION_COMMON_appreciate") },
  { id: "只作記錄", label: t("OPTION_COMMON_record_only") }
]

export const adjudicateOptionList = [
  { id: t("OPTION_COMMON_adjudicate_1"), label: t("OPTION_COMMON_adjudicate_1") },
  { id: t("OPTION_COMMON_adjudicate_2"), label: t("OPTION_COMMON_adjudicate_2") },
  { id: t("OPTION_COMMON_adjudicate_3"), label: t("OPTION_COMMON_adjudicate_3") },
  { id: t("OPTION_COMMON_adjudicate_4"), label: t("OPTION_COMMON_adjudicate_4") },
  { id: t("OPTION_COMMON_adjudicate_5"), label: t("OPTION_COMMON_adjudicate_5") },
  { id: t("OPTION_COMMON_adjudicate_6"), label: t("OPTION_COMMON_adjudicate_6") },
  { id: t("OPTION_COMMON_adjudicate_7"), label: t("OPTION_COMMON_adjudicate_7") },
  { id: t("OPTION_COMMON_adjudicate_8"), label: t("OPTION_COMMON_adjudicate_8") },
  { id: t("OPTION_COMMON_adjudicate_9"), label: t("OPTION_COMMON_adjudicate_9") },
  { id: t("OPTION_COMMON_adjudicate_10"), label: t("OPTION_COMMON_adjudicate_10") },
  { id: t("OPTION_COMMON_adjudicate_11"), label: t("OPTION_COMMON_adjudicate_11") },
  { id: t("OPTION_COMMON_adjudicate_12"), label: t("OPTION_COMMON_adjudicate_12") },
  { id: t("OPTION_COMMON_adjudicate_13"), label: t("OPTION_COMMON_adjudicate_13") },
  { id: t("OPTION_COMMON_adjudicate_14"), label: t("OPTION_COMMON_adjudicate_14") }
]

export const orderStatusList = [
  { id: "created", label: t("OPTION_COMMON_order_status_created") },
  { id: "pairing", label: t("OPTION_COMMON_order_status_paring") },
  { id: "matched", label: t("OPTION_COMMON_order_status_matched") },
  { id: "processing", label: t("OPTION_COMMON_order_status_processing") },
  { id: "finished", label: t("OPTION_COMMON_order_status_finished") },
  { id: "cancelled", label: t("OPTION_COMMON_order_status_cancelled") },
  { id: "request_refund", label: t("OPTION_COMMON_order_status_request_refund") },
  { id: "refunded", label: t("OPTION_COMMON_order_status_refunded") }
]

export const orderStatusDoc = orderStatusList.reduce((obj, currnt) => ({ ...obj, [currnt.id]: currnt }), {})

export const discountTypeOptionList = [
  { id: "Percentage Discount", label: t("OPTION_COMMON_discount_type_discount") },
  { id: "Amount Discount", label: t("OPTION_COMMON_discount_type_cash") },
  { id: "Discount Code", label: t("OPTION_COMMON_discount_type_promocode") },
  { id: "Free Trial", label: t("OPTION_COMMON_discount_type_free") },
  { id: "Package Discount", label: t("OPTION_COMMON_discount_type_combo") }
]

export const discountTypeOptionDoc = discountTypeOptionList.reduce(
  (obj, currnt) => ({ ...obj, [currnt.id]: currnt }),
  {}
)

export const discountTargetOptionList = [
  { id: "所有用戶", label: t("E_COMMERCE_DISCOUNT_Label_all_customers") },
  { id: "指定用戶", label: t("E_COMMERCE_DISCOUNT_Label_specify_customers") }
]

export const percentAmoutOptionList = [
  { id: "percentage", label: t("OPTION_COMMON_percent_amout_percent") },
  { id: "amount", label: t("OPTION_COMMON_percent_amout_amount") }
]

export const percentAmoutOptionDoc = percentAmoutOptionList.reduce(
  (obj, currnt) => ({ ...obj, [currnt.id]: currnt }),
  {}
)

export const usedServiceSessionOptionList = [
  { id: "max", label: t("OPTION_COMMON_used_service_session_max") },
  { id: "min", label: t("OPTION_COMMON_used_service_session_min") }
]

export const bannerLocationOptionList = [
  { id: "before", label: t("OPTION_COMMON_banner_location_before") },
  { id: "after", label: t("OPTION_COMMON_banner_location_after") }
]

export const faqStateOptionList = [
  { id: true, label: t("OPTION_COMMON_faq_state_true") },
  { id: false, label: t("OPTION_COMMON_faq_state_false") }
]

export const monthOptionList = [
  { id: 1, label: "1月" },
  { id: 2, label: "2月" },
  { id: 3, label: "3月" },
  { id: 4, label: "4月" },
  { id: 5, label: "5月" },
  { id: 6, label: "6月" },
  { id: 7, label: "7月" },
  { id: 8, label: "8月" },
  { id: 9, label: "9月" },
  { id: 10, label: "10月" },
  { id: 11, label: "11月" },
  { id: 12, label: "12月" }
]

export const yearOptionList = [
  { id: 2022, label: "2022年" },
  { id: 2023, label: "2023年" },
  { id: 2024, label: "2024年" },
  { id: 2025, label: "2025年" },
  { id: 2026, label: "2026年" },
  { id: 2027, label: "2027年" },
  { id: 2028, label: "2028年" },
  { id: 2029, label: "2029年" },
  { id: 2030, label: "2030年" },
  { id: 2031, label: "2031年" },
  { id: 2032, label: "2032年" }
]

export const orderByOptionDoc = {
  desc: "desc",
  asc: "asc"
}
export const orderByOptionList = ["desc", "asc"]

const getDurationTimeList = (minMinute = 0, intervalMinute = 15) => {
  let list = []

  for (let min = minMinute; min <= 720; min = min + intervalMinute) {
    list.push({ id: min / 60, label: min / 60 })
  }

  return list
}

export const addTimeList = getDurationTimeList(30, 30)
export const sessionTimeList = getDurationTimeList(60)

export const paymentStatusOptionList = [
  { id: "unpaid", label: t("OPTION_COMMON_unpaid") },
  { id: "paid", label: t("OPTION_COMMON_paid") },
  { id: "partially paid", label: t("OPTION_COMMON_partially_paid") }
]

export const paymentStatusOptionDoc = paymentStatusOptionList.reduce(
  (obj, currnt) => ({ ...obj, [currnt.id]: currnt }),
  {}
)

export const paymentMethodOptionList = [
  { id: "credit_card", label: t("OPTION_COMMON_payment_method_credit_card") },
  // { id: "cheque", label: t("OPTION_COMMON_payment_method_cheque") },
  // { id: "discount", label: t("OPTION_COMMON_payment_method_discount") },
  { id: "ehs_dollar", label: t("OPTION_COMMON_payment_method_cash_dollar") },
  { id: "invoice", label: t("OPTION_COMMON_payment_method_invoice") }
]

export const orderTypeDoc = {
  default: "default",
  ot: "ot",
  refund: "refund"
}

export const deletedOptionList = [
  { id: true, label: t("OPTION_COMMON_deleted_edited") },
  { id: false, label: t("OPTION_COMMON_deleted_submitted") }
]

export const tmRemarkStatusOptionList = [
  { id: "approved", label: t("OPTION_COMMON_Approved") },
  { id: "submitted", label: t("OPTION_COMMON_Submitted") },
  { id: "rejected", label: t("OPTION_COMMON_Rejected") }
]

export const transactionTypeOptionList = [
  { id: "adjustment", label: t("OPTION_COMMON_transaction_type_adjustment") },
  { id: "refund", label: t("OPTION_COMMON_transaction_type_refund") },
  { id: "payment", label: t("OPTION_COMMON_transaction_type_payment") }
]

export const rateOptionList = [
  { id: 1, label: 1 },
  { id: 2, label: 2 },
  { id: 3, label: 3 },
  { id: 4, label: 4 },
  { id: 5, label: 5 }
]

export const approve418StatusOptionList = [
  { id: "accepted", label: t("OPTION_COMMON_Approved") },
  { id: "pending", label: t("OPTION_COMMON_Submitted") },
  { id: "rejected", label: t("OPTION_COMMON_Rejected") }
]

export const receiverAccordingOptionList = [
  { id: "type", label: t("OPTION_COMMON_receiver_according_type") },
  { id: "id", label: t("OPTION_COMMON_receiver_according_Id") }
]

export const receiverTypeOptionList = [
  { id: "a", label: t("OPTION_COMMON_target_type_all") },
  { id: "c", label: t("OPTION_COMMON_target_type_customer") },
  { id: "t", label: t("OPTION_COMMON_target_type_tm") }
]

export const targetTypeOptionList = [
  { id: "a", label: t("OPTION_COMMON_target_type_all") },
  { id: "c", label: t("OPTION_COMMON_target_type_customer") },
  { id: "t", label: t("OPTION_COMMON_target_type_tm") },
  { id: "x", label: t("OPTION_COMMON_target_type_csv") }
]

export const chequeStateOptionList = [
  { id: "P", label: t("OPTION_COMMON_pending") },
  { id: "A", label: t("OPTION_COMMON_accepted") },
  { id: "R", label: t("OPTION_COMMON_rejected") }
]

export const chequeStateOptionDoc = chequeStateOptionList.reduce((obj, currnt) => ({ ...obj, [currnt.id]: currnt }), {})

export const payStateOptionList = [
  { id: "U", label: t("OPTION_COMMON_unpaid") },
  { id: "P", label: t("OPTION_COMMON_partially_paid") },
  { id: "F", label: t("OPTION_COMMON_paid") }
]

export const payStateOptionListDoc = payStateOptionList.reduce((obj, currnt) => ({ ...obj, [currnt.id]: currnt }), {})

export const TMMiscChargeTypeOptionList = [
  { id: "PALLOW-TM", label: t("OPTION_COMMON_PALLOW_TM") },
  { id: "TRANS-TM", label: t("OPTION_COMMON_TRANS_TM") },
  { id: "MISC_TM", label: t("OPTION_COMMON_MISC_TM") },
  { id: "BSB-PT", label: t("OPTION_COMMON_BSB_PT") },
  { id: "SPINC-TM", label: t("OPTION_COMMON_SPINC-TM") }
]

export const hasVoucherOptionList = [
  { id: true, label: t("OPTION_COMMON_has") },
  { id: false, label: t("OPTION_COMMON_has_not") }
]
