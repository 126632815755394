import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getTMRemarks = (payload = {}) => {
  let params = formatPayloadToParams(payload, [
    "orderId",
    "status",
    "orderNo",
    "customerId",
    "customer.name",
    "user.name",
    "tmUser.nameZh",
    "sort",
    "order"
  ])
  return http.get("tm-remarks", { params })
}

export const putTMRemarkById = ({ TMRemarkId, TMRemarkInfo }) => {
  return http.put(`tm-remarks/${TMRemarkId}`, TMRemarkInfo)
}

export const deleteTMRemarkById = (TMRemarkId) => {
  return http.put(`tm-remarks/${TMRemarkId}`, { status: "rejected" })
}
