import axios from "axios"
import { deleteCookieToken, getCookieToken } from "@constants"
import isBrowser from "@utils/isBrowser"
import apiBaseUrl from "@utils/apiBaseUrl"
import Router from "next/router"

let http = axios.create({ baseURL: apiBaseUrl })

// const pending = new Map()

// const addPending = (config) => {
//   const key = [config.method, config.url].join("/")
//   const controller = new AbortController()
//   config.signal = controller.signal
//   if (!pending.has(key)) pending.set(key, controller)
// }

// const removePending = (config) => {
//   const key = [config.method, config.url].join("/")
//   if (pending.has(key)) {
//     const controller = pending.get(key)
//     controller.abort()
//     pending.delete(key)
//   }
// }

http.interceptors.request.use(
  (configs) => {
    // removePending(configs)
    // addPending(configs)
    configs.headers["X-CSRF-Token"] = "ehs2022"
    if (isBrowser) {
      let Token = getCookieToken()
      if (Token) {
        configs.headers.Authorization = `Bearer ${Token}`
      }
    }
    return configs
  },
  (error) => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response) => {
    log(response.config, response.data)
    // removePending(response)
    return response.data
  },
  // error
  (error) => {
    // if (axios.isCancel(error)) return {}
    let { response } = error
    if (isBrowser && response) {
      log(response.config, response.data, true)
      if (errorCodeCheck(response.status)) {
        deleteCookieToken()
        Router.push({ pathname: "/login" })
      }
    }
    return Promise.reject(response)
  }
)

export const errorCodeCheck = (status) => {
  return status === 401
}

function log({ url, method }, text, error = false) {
  console.log(`%c ${method}/${url} `, `color: white; background-color: #${error ? "f66361" : "95B46A"}`, text)
}

export default http
