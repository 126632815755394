import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getTM418Requests = (payload = {}) => {
  let params = formatPayloadToParams(payload, [
    "orderNo",
    "createdBy",
    "tmUser.nameZh",
    "tmUser.staffNo",
    "tmUser.phone",
    "sort",
    "order"
  ])
  return http.get("tm-418-requests", { params })
}

export const putTM418RequestById = ({ id, payload }) => {
  return http.put(`tm-418-requests/${id}`, payload)
}
