import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getTMPayrolls = (payload = {}) => {
  let params = formatPayloadToParams(payload, ["startDate", "endDate"])
  return http.get("tm-payrolls", { params })
}

export const postTMPayrolls = (payload) => {
  return http.post("tm-payrolls", payload)
}

export const getTMPayrollsById = ({ id, params }) => {
  return http.get(`tm-payrolls/${id}/export`, { params })
}
