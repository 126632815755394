import http from "./apiServices"
import { formatPayloadToParams } from "@utils/formatter"

export const getEHSDollarLogs = (payload = {}) => {
  let params = formatPayloadToParams(payload, [
    "customer.type",
    "customer.customerId",
    "customer.customerNo",
    "customer.name",
    "customer.phone",
    "orderNo",
    "invoiceNo",
    "sort",
    "order"
  ])
  return http.get("dollarLogs", { params })
}

export const postEHSDollarLogs = (adminTypeInfo) => {
  return http.post("dollarLogs", adminTypeInfo)
}
