import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import worddict from "@public/locales/wordDict.json"

const resources = {
  zh: {
    translation: worddict.zh
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: "zh",
  keySeparator: false,
  interpolation: {
    escapeValue: false // react already safes from xss
  }
})

export default i18n
