import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { AdminsApi } from "../apis"
import { isEmpty } from "@utils/validator"

export const getAdmins = createAsyncThunk("admins/getAdmins", async (payload) => {
  return await AdminsApi.getAdmins(payload)
})

export const postAdmin = createAsyncThunk("admins/postAdmin", async (payload) => {
  return await AdminsApi.postAdmin(payload)
})

export const getAdminById = createAsyncThunk("admins/getAdminById", async (payload) => {
  const data = await AdminsApi.getAdminById(payload)
  return { isMe: isEmpty(payload), ...data }
})

export const putAdminById = createAsyncThunk("admins/putAdminById", async (payload) => {
  return await AdminsApi.putAdminById(payload)
})

export const deleteAdminById = createAsyncThunk("admins/deleteAdminById", async (payload) => {
  return await AdminsApi.deleteAdminById(payload)
})

const initialState = { me: null }

const adminsSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {
    clearMe(state) {
      state.me = undefined
    },
    setMe(state, { payload }) {
      state.me = payload
    }
  },
  extraReducers: {
    [getAdminById.pending]: (state, action) => {},
    [getAdminById.fulfilled]: (state, { payload }) => {
      if (payload.isMe) {
        state.me = payload
        delete state.me.isMe
      }
    },
    [getAdminById.rejected]: (state, { error }) => {}
  }
})

export const { clearMe, setMe } = adminsSlice.actions
export default adminsSlice.reducer
