import { map } from "lodash"

const partitionDoc = {
  north: [
    { id: "TKUL", label: "打鼓嶺" },
    { id: "FL", label: "粉嶺" },
    { id: "FT", label: "粉嶺軍地" },
    { id: "KWT", label: "古洞" },
    { id: "PGC", label: "坪輋" },
    { id: "STK", label: "沙頭角" },
    { id: "SS", label: "上水" }
  ],
  "tai po": [
    { id: "TP", label: "大埔" },
    { id: "SW", label: "大埔船灣" },
    { id: "TPK", label: "大埔滘" },
    { id: "LT", label: "大埔林村" },
    { id: "TK", label: "大埔汀角" },
    { id: "TWE", label: "太和" }
  ],
  eastern: [
    { id: "NPT", label: "北角" },
    { id: "CWN", label: "柴灣" },
    { id: "SKW", label: "筲箕灣" },
    { id: "SWHO", label: "西灣河" },
    { id: "SSW", label: "小西灣" },
    { id: "QBY", label: "鰂魚涌" }
  ],
  "kwun tong": [
    { id: "CKL", label: "茶果嶺" },
    { id: "KT", label: "觀塘" },
    { id: "KBY", label: "九龍灣" },
    { id: "LTN", label: "藍田" },
    { id: "NTK", label: "牛頭角" },
    { id: "SMP", label: "秀茂坪" },
    { id: "YTN", label: "油塘" }
  ],
  "wong tai sin": [
    { id: "CHN", label: "彩虹" },
    { id: "CHW", label: "彩雲" },
    { id: "TWS", label: "慈雲山" },
    { id: "WTH", label: "橫頭磡" },
    { id: "WTS", label: "黃大仙" },
    { id: "LFU", label: "樂富" },
    { id: "NCW", label: "牛池灣" },
    { id: "SPOK", label: "新蒲崗" },
    { id: "DHL", label: "鑽石山" }
  ],
  "kowloon city": [
    { id: "HMT", label: "何文田" },
    { id: "HHM", label: "紅磡" },
    { id: "KC", label: "九龍城" },
    { id: "KWTN", label: "九龍塘" },
    { id: "MTW", label: "馬頭圍" },
    { id: "TKW", label: "土瓜灣" },
    { id: "YYC", label: "又一村" }
  ],
  "kwai tsing": [
    { id: "KCH", label: "葵涌" },
    { id: "TY", label: "青衣" }
  ],
  islands: [
    { id: "CCH", label: "長洲" },
    { id: "TPIC", label: "東坪洲" },
    { id: "LMI", label: "南丫島" },
    { id: "PCH", label: "坪洲" },
    { id: "HLC", label: "喜靈洲" }
  ],
  "islands (lantau island)": [
    { id: "LI", label: "大嶼山" },
    { id: "NP", label: "大嶼山昂坪" },
    { id: "CS", label: "大嶼山長沙" },
    { id: "CLK", label: "大嶼山赤鱲角" },
    { id: "TO", label: "大嶼山大澳" },
    { id: "TC", label: "大嶼山東涌" },
    { id: "MW", label: "大嶼山梅窩" },
    { id: "SP", label: "大嶼山石壁" },
    { id: "TF", label: "大嶼山塘福" },
    { id: "DB", label: "大嶼山愉景灣" },
    { id: "CMW", label: "大嶼山芝麻灣" }
  ],
  southern: [
    { id: "PFL", label: "薄扶林" },
    { id: "STL", label: "赤柱" },
    { id: "CHK", label: "舂坎角" },
    { id: "TT", label: "大潭" },
    { id: "WCHH", label: "黃竹坑" },
    { id: "RB", label: "淺水灣" },
    { id: "DWB", label: "深水灣" },
    { id: "SKO", label: "石澳" },
    { id: "ABD", label: "香港仔" },
    { id: "ALC", label: "鴨脷洲" }
  ],
  "tsuen wan": [
    { id: "LWT", label: "老圍村" },
    { id: "MWN", label: "馬灣" },
    { id: "TW", label: "荃灣" },
    { id: "STG", label: "深井" },
    { id: "TKA", label: "汀九" }
  ],
  "sha tin": [
    { id: "CH", label: "九肚山" },
    { id: "MOS", label: "馬鞍山" },
    { id: "MLS", label: "馬料水" },
    { id: "ST", label: "沙田" },
    { id: "TWA", label: "沙田大圍" },
    { id: "FTA", label: "沙田火炭" },
    { id: "SLK", label: "沙田小瀝源" },
    { id: "WKS", label: "烏溪沙" }
  ],
  "sham shui po": [
    { id: "CSW", label: "長沙灣" },
    { id: "LCK", label: "荔枝角" },
    { id: "MF", label: "美孚" },
    { id: "SSP", label: "深水埗" },
    { id: "SHKM", label: "石硤尾" }
  ],
  "tuen mun": [
    { id: "TLAM", label: "大欖" },
    { id: "SKWW", label: "掃管笏" },
    { id: "TMU", label: "屯門" },
    { id: "FUT", label: "屯門虎地" },
    { id: "LTE", label: "屯門藍地" },
    { id: "SL", label: "屯門小欖" },
    { id: "TMSH", label: "屯門新墟" }
  ],
  "wan chai": [
    { id: "THG", label: "大坑" },
    { id: "HPV", label: "跑馬地" },
    { id: "CWB", label: "銅鑼灣" },
    { id: "WC", label: "灣仔" }
  ],
  "sai kung": [
    { id: "TKO", label: "將軍澳" },
    { id: "CLWB", label: "清水灣" },
    { id: "SK", label: "西貢" },
    { id: "HH", label: "坑口" }
  ],
  "yau tsim mong": [
    { id: "TKT", label: "大角咀" },
    { id: "TST", label: "尖沙咀" },
    { id: "MKK", label: "旺角" },
    { id: "YMT", label: "油麻地" },
    { id: "JRD", label: "佐敦" }
  ],
  "yuen long": [
    { id: "HSK", label: "洪水橋" },
    { id: "LFS", label: "流浮山" },
    { id: "TSW", label: "天水圍" },
    { id: "YL", label: "元朗" },
    { id: "PH", label: "元朗八鄉" },
    { id: "TAT", label: "元朗大棠" },
    { id: "KAT", label: "元朗錦田" },
    { id: "PIS", label: "元朗屏山" },
    { id: "SHK", label: "元朗石崗" },
    { id: "TM", label: "元朗潭尾" },
    { id: "HT", label: "元朗廈村" }
  ],
  "central and western": [
    { id: "ML", label: "半山" },
    { id: "KNT", label: "堅尼地城" },
    { id: "AD", label: "金鐘" },
    { id: "PEK", label: "山頂" },
    { id: "SWN", label: "上環" },
    { id: "STT", label: "石塘咀" },
    { id: "SWA", label: "西環" },
    { id: "SYP", label: "西營盤" },
    { id: "CTL", label: "中環" }
  ]
}

export const partitionList = (district) => {
  return district ? partitionDoc[district] : []
}

export const areaList = [
  { id: "HK", label: "香港" },
  { id: "KLN", label: "九龍" },
  { id: "NT", label: "新界" }
]

export const HKdistrictList = [
  { id: "central and western", label: "中西區" },
  { id: "eastern", label: "東區" },
  { id: "southern", label: "南區" },
  { id: "wan chai", label: "灣仔" }
]
export const KLNdistrictList = [
  { id: "kowloon city", label: "九龍城" },
  { id: "kwun tong", label: "觀塘" },
  { id: "sham shui po", label: "深水埗" },
  { id: "wong tai sin", label: "黃大仙" },
  { id: "yau tsim mong", label: "油尖旺" }
]
export const NTdistrictList = [
  { id: "north", label: "北區" },
  { id: "tai po", label: "大埔" },
  { id: "kwai tsing", label: "葵青" },
  { id: "islands", label: "離島" },
  { id: "islands (lantau island)", label: "離島(大嶼山)" },
  { id: "tsuen wan", label: "荃灣" },
  { id: "sha tin", label: "沙田" },
  { id: "tuen mun", label: "屯門" },
  { id: "sai kung", label: "西貢" },
  { id: "yuen long", label: "元朗" }
]

export const districtList = [...HKdistrictList, ...KLNdistrictList, ...NTdistrictList]

export const districtDoc = districtList.reduce((obj, currnt) => ({ ...obj, [currnt.id]: currnt }), {})

export const partitionFlatDoc = map(partitionDoc, (val) => val.flat()).flat()
